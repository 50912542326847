/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.editor {
  position: relative;
  z-index: 0;
  height: 100%;
  overflow: auto;
}
.editor .loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editor .loading :global .ant-spin-container {
  height: 100%;
}
.editor .editor-container {
  height: 100%;
}
.editor .editor-container :global .didoc-editor-app .editor-menu-wrapper {
  display: none;
}
.editor .editor-container :global .didoc-editor-app .editor .editor-title {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333 !important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
  padding: 18px 24px!important;
}
.editor .editor-container :global .didoc-editor-app .editor .didoc-editor-container {
  padding: 0 24px!important;
}
.editor .editor-container :global .didoc-editor-app .editor .loading {
  display: none;
}
