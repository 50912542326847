/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.folder-tree {
  height: 100%;
  position: relative;
  min-height: 400px;
  overflow-x: hidden;
}
.folder-tree:hover {
  overflow-x: auto;
}
.folder-tree .ft-triangle-wrapper {
  height: 30px;
  width: 19px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.folder-tree .tb-header,
.folder-tree .tb-body > li {
  height: 46px;
  line-height: 46px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: rgba(34, 42, 53, 0.7);
}
.folder-tree .tb-header > span,
.folder-tree .tb-body > li > span {
  display: inline-block;
}
.folder-tree .tb-header .file-name,
.folder-tree .tb-body > li .file-name {
  width: 300px;
  min-width: 250px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex: 1;
}
.folder-tree .tb-header .file-name .file-name-display,
.folder-tree .tb-body > li .file-name .file-name-display {
  display: inline-flex;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}
.folder-tree .tb-header .file-name .file-name-display-text,
.folder-tree .tb-body > li .file-name .file-name-display-text {
  line-height: 1;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}
.folder-tree .tb-header .file-name .file-name-display-count,
.folder-tree .tb-body > li .file-name .file-name-display-count {
  line-height: 1;
  color: rgba(34, 42, 53, 0.5);
  padding-left: 6px;
  font-size: 12px;
  margin-top: 2px;
  cursor: default !important;
}
.folder-tree .tb-header .file-name > .ant-checkbox-wrapper,
.folder-tree .tb-body > li .file-name > .ant-checkbox-wrapper {
  position: relative;
  top: -2px;
  margin-right: 18px;
}
.folder-tree .tb-header .file-resizer,
.folder-tree .tb-body > li .file-resizer {
  width: 20px;
  margin-right: 8px;
  margin-left: 52px;
  position: relative;
  text-align: center;
}
.folder-tree .tb-header .file-resizer .resizer-line,
.folder-tree .tb-body > li .file-resizer .resizer-line {
  width: 1px;
  height: 50px;
  background: #076BFF;
  position: absolute;
  top: 0;
  left: 50%;
}
.folder-tree .tb-header > .file-owner,
.folder-tree .tb-body > li > .file-owner,
.folder-tree .tb-header > .file-time,
.folder-tree .tb-body > li > .file-time {
  width: 180px;
  min-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  color: rgba(34, 42, 53, 0.7);
}
.folder-tree .tb-header > .file-owner :global .path-ellipsis,
.folder-tree .tb-body > li > .file-owner :global .path-ellipsis,
.folder-tree .tb-header > .file-time :global .path-ellipsis,
.folder-tree .tb-body > li > .file-time :global .path-ellipsis {
  cursor: default !important;
}
.folder-tree .tb-header > .file-time,
.folder-tree .tb-body > li > .file-time {
  min-width: 100px;
}
.folder-tree .tb-header > .file-operate,
.folder-tree .tb-body > li > .file-operate {
  width: 40px;
  min-width: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 20px;
  display: inline-block;
}
.folder-tree .tb-header .triangle,
.folder-tree .tb-body > li .triangle {
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 7px;
  margin-right: 10px;
  background-size: 8px 8px;
  background-image: url(https://img-ys011.didistatic.com/static/cooper_cn/do1_Bw1673hz9vMmk8p48HJH);
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.folder-tree .tb-header .triangle-li,
.folder-tree .tb-body > li .triangle-li {
  margin-left: 4px;
}
.folder-tree .tb-header .triangle.is-open,
.folder-tree .tb-body > li .triangle.is-open,
.folder-tree .tb-header .triangle.sort,
.folder-tree .tb-body > li .triangle.sort {
  transform: translateY(-15%) rotate(90deg);
}
.folder-tree .tb-header .triangle.is-open.sort-up,
.folder-tree .tb-body > li .triangle.is-open.sort-up,
.folder-tree .tb-header .triangle.sort.sort-up,
.folder-tree .tb-body > li .triangle.sort.sort-up {
  transform: rotate(270deg);
}
.folder-tree .tb-header {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  min-width: 100%;
  height: 36px !important;
  line-height: 36px !important;
  border-bottom: 1px solid rgba(34, 42, 53, 0.08);
}
.folder-tree .tb-header .file-operate,
.folder-tree .tb-header .file-owner,
.folder-tree .tb-header .file-time,
.folder-tree .tb-header .file-name {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 18px;
}
.folder-tree .tb-header .file-operate .ant-table-column-sorter .anticon-caret-up,
.folder-tree .tb-header .file-owner .ant-table-column-sorter .anticon-caret-up,
.folder-tree .tb-header .file-time .ant-table-column-sorter .anticon-caret-up,
.folder-tree .tb-header .file-name .ant-table-column-sorter .anticon-caret-up,
.folder-tree .tb-header .file-operate .ant-table-column-sorter .anticon-caret-down,
.folder-tree .tb-header .file-owner .ant-table-column-sorter .anticon-caret-down,
.folder-tree .tb-header .file-time .ant-table-column-sorter .anticon-caret-down,
.folder-tree .tb-header .file-name .ant-table-column-sorter .anticon-caret-down {
  font-weight: bold;
}
.folder-tree .tb-header > li.bottom-tip,
.folder-tree .tb-body > li.bottom-tip {
  pointer-events: none;
  border: none;
  color: rgba(34, 42, 53, 0.7);
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 32px;
}
.folder-tree .tb-body {
  height: calc(100% - 68px);
  min-width: 100%;
  padding-top: 8px;
}
.folder-tree .tb-body-row:hover {
  border-radius: 4px;
  background: rgba(34, 42, 53, 0.06);
}
.folder-tree :global .ant-btn {
  min-width: 60px;
  width: auto;
  height: 32px;
  border: 1px solid #DDDDDD !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.folder-tree :global .ant-btn:hover,
.folder-tree :global .ant-btn:focus {
  border-color: #518dff;
  color: #518dff;
  background-color: #fff;
}
.folder-tree .loading-tip {
  margin-top: 14px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.folder-tree .loading-tip .text {
  margin-left: 4px;
}
.confirm-modal :global .ant-modal-content {
  border-radius: 8px;
}
.confirm-modal :global .ant-modal-confirm-title {
  color: #222A35;
  max-width: 300px;
}
.confirm-modal :global .ant-modal-confirm-content {
  color: rgba(34, 42, 53, 0.6);
  font-size: 14px;
  margin-top: 16px;
}
.confirm-modal-close {
  color: rgba(34, 42, 53, 0.4);
  font-size: 24px;
  position: absolute;
  top: 32px;
  right: 32px;
}
.delete-modal :global .ant-btn-primary {
  background-color: #FF563B !important;
  border: 1px solid #FF563B !important;
}
.message-link {
  color: #047FFE;
  cursor: pointer;
}
