/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.member-container .member-wrapper {
  padding: 0 30px 12px 44px;
}
.member-container .member-wrapper .member-item {
  position: relative;
  padding: 17px 0 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-radius: 8px;
}
.member-container .member-wrapper .member-item .metas {
  display: flex;
}
.member-container .member-wrapper .member-item .metas .member-avatar {
  margin-right: 12px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  overflow: hidden;
}
.member-container .member-wrapper .member-item .metas .member-title-wrap {
  display: flex;
  align-items: center;
}
.member-container .member-wrapper .member-item .metas .member-title-wrap .member-username {
  max-width: 196px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  color: #111111;
  line-height: 16px;
  display: inline-block;
}
.member-container .member-wrapper .member-item .metas .member-title-wrap .member-username.oneLine {
  line-height: 36px;
}
.member-container .member-wrapper .member-item .metas .member-title-wrap .role-tag {
  font-size: 10px;
  font-weight: 500;
  color: #047FFE;
  line-height: 14px;
  padding: 1px 4px;
  background: rgba(4, 127, 254, 0.08);
  border-radius: 2px;
  margin-left: 5px;
}
.member-container .member-wrapper .member-item .metas .member-title-wrap .name-label-dimissed {
  margin-left: 6px;
  padding: 3px 3px;
  height: 16px;
  line-height: 10px;
  background: rgba(0, 102, 255, 0.08);
  border-radius: 4px;
  font-size: 12px;
  background: #eff0f2;
  color: #666;
}
.member-container .member-wrapper .member-item .metas .member-email {
  display: inline-block;
  margin-top: 7px;
  height: 12px;
  font-size: 12px;
  color: #999999;
  line-height: 12px;
}
.member-container .member-wrapper .member-item .identity-select-wrap .identity-select {
  right: 0 ;
}
