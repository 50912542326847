/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.page-views {
  display: inline-block;
  align-items: center;
  color: #8c8c8c;
  font-size: 12px;
}
.page-views .view-num {
  width: 14px;
  height: 14px;
  display: inline-block;
  white-space: nowrap;
}
.page-views .yuLanIcon {
  font-size: 12px;
  margin: 0 4px 0 20px;
}
