// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.send-to-iportal-modal {
  width: 500px!important;
  border-radius: 8px;
  padding-bottom: 0!important;
  overflow: hidden;

  :global {
    .ant-modal-content {
      .ant-modal-body {
        padding: 12px 0;
      }
    }
  }
}

.send-to-iportal-modal-content {
  display: flex;
  flex-direction: column;

  .send-to-iportal-modal-header {
    display: flex;
    align-items: center;
    padding: 12px 24px;

    .send-to-iportal-modal-header-logo {
      width: 20px;
      margin-right: 5px;
    }

    .send-to-iportal-modal-header-text {
      font-size: 16px;
      font-weight: 500;
      color: #222A35;
    }
  }

  .send-to-iportal-modal-main {
    flex: 1;
    max-height: calc(100vh - 210px);
    display: flex;
    flex-direction: column;
    padding: 12px 0 6px 24px;
    gap: 24px;
    overflow: auto;

    .send-to-iportal-modal-main-section {
      width: 452px;

      .send-to-iportal-modal-main-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222A35;

        &::after {
          content: ' *';
          color: #FF563B;
        }
      }

      .send-to-iportal-modal-main-content {
        display: flex;
        gap: 12px;
        margin-top: 8px;

        .send-to-iportal-modal-radio-group {
          display: flex;
          align-items: center;
          gap: 30px;

          .send-to-iportal-modal-radio-item {
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            color: #222A35;
            cursor: pointer;

            .send-to-iportal-modal-selected-icon {
              margin-right: 6px;
              font-size: 18px;
              color: #a7aaae;
            }

            &.is-active,
            &:hover {
              border-color: rgba(4, 127, 254, 0.6);

              .send-to-iportal-modal-selected-icon {
                color: #047FFE;
              }
            }
          }
        }

        .send-to-iportal-modal-main-content-left,
        .send-to-iportal-modal-main-content-right {
          display: flex;
          gap: 6px;
          width: 220px;
          min-height: 63px;
          padding: 12px;
          border-radius: 4px;
          box-sizing: border-box;
          border: 1px solid #D3D4D7;
          cursor: pointer;

          .send-to-iportal-modal-selected-icon {
            font-size: 18px;
            color: #A7AAAE;
            margin-top: 3px;
          }

          &.is-active,
          &:hover {
            border-color: rgba(4, 127, 254, 0.6);

            .send-to-iportal-modal-selected-icon {
              color: #047FFE;
            }
          }

          &.is-active {
            background: url("../../assets/icon/iportal_bg.png") no-repeat right bottom/contain;
          }
        }

        .send-to-iportal-modal-main-content-summary {
          .send-to-iportal-modal-main-content-summary-title {
            font-size: 14px;
            color: #222A35;
          }

          .send-to-iportal-modal-main-content-summary-desc {
            font-size: 12px;
            font-weight: normal;
            color: #909499;
          }
        }
      }

      .send-to-iportal-modal-main-panel {
        padding: 12px;
        border-radius: 6px;
        background: #F4F7F8;

        .send-to-iportal-modal-main-notice {
          display: flex;
          align-items: center;
          gap: 6px;
          cursor: pointer;

          font-size: 14px;
          color: #222A35;

          &.is-active, &:hover {
            .send-to-iportal-modal-main-notice-icon {
              color: #047FFE;
            }
          }

          .send-to-iportal-modal-main-notice-icon {
            font-size: 18px;
            color: #A7AAAE;
            cursor: pointer;
          }
        }

        .send-to-iportal-modal-main-notice-list {
          margin: 8px 0 0 2px;
          padding: 0;
          font-size: 12px;
          color: #656A72;
        }

        .send-to-iportal-modal-main-notice-list-item {
          list-style-type: decimal;
          list-style-position: inside;
          margin-top: 4px;
          line-height: normal;
        }
      }
    }
  }

  .send-to-iportal-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 16px 24px 12px;

    .send-to-iportal-modal-cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 32px;
      border-radius: 3px;
      border: 1px solid #EEEEEF;
      outline: none;
      font-size: 14px;
      color: #222A35;
      cursor: pointer;
      background: #fff;

      &:hover {
        background: #F2F3F3;
      }
    }

    .send-to-iportal-modal-confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 172px;
      height: 32px;
      font-size: 14px;
      outline: none;
      border-radius: 4px;
      background: #047FFE;
      border: none;
      cursor: pointer;
      color: #fff;

      &:hover {
        background: rgba(4, 127, 254, 0.8);
      }

      &:disabled {
        background: rgba(4, 127, 254, 0.4);
        cursor: not-allowed;
      }

      &.is-loading {
        background: rgba(4, 127, 254, 0.4);
      }

      .send-to-iportal-modal-confirm-icon {
        margin-right: 6px;
      }
    }
  }
}

.send-to-iportal-modal-close-btn {
  position: absolute;
  right: 24px;
  top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: #7A7F86;

  .send-to-iportal-modal-close-icon {
    font-size: 20px;
  }

  &:hover {
    background: #F2F3F3;
  }
}

.send-to-iportal-modal-select {
  width: 100%!important;

  &:global {
    &.ant-select-focused:not(.ant-select-open) {
      .ant-select-selector {
        border-color: #d3d4d7!important;
      }
    }
  }

  &:global {
    &.ant-select-disabled {
      .ant-select-selector:hover {
        border-color: #d3d4d7!important;
      }
    }
  }

  :global {
    .ant-select-selector {
      height: 36px!important;
      align-items: center;
      border-radius: 4px!important;
      border: 1px solid #D3D4D7!important;
    }

    .ant-select-selection-item {
      line-height: 1;
      display: block;
      font-size: 14px;
      color: #222A35!important;
    }
  }

  .send-to-iportal-modal-select-suffix-icon {
    color: #333333;
  }
}

.send-to-iportal-modal-select-empty {
  text-align: center;
  font-size: 12px;
  color: #656A72;
}

.send-to-iportal-modal-select-option {
  border-radius: 6px!important;
  border: 1px solid #E8E9EA;
  box-shadow: 0px 6px 24px -3px rgba(0, 0, 0, 0.12);

  :global {
    .ant-select-item {
      padding: 6px 12px 6px 8px!important;
      border-radius: 6px;
      background: #fff!important;

      &.ant-select-item-option-active {
        background: #F2F3F3!important;
      }

      &.ant-select-item-option-selected {
        :local {
          .send-to-iportal-modal-select-option-selected-icon {
            display: block;
          }
        }
      }
    }
  }
}

.send-to-iportal-modal-select-option-item {
  display: flex;
  align-items: center;
  gap: 10px;

  .send-to-iportal-modal-select-option-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }

  .send-to-iportal-modal-select-option-content {
    flex: 1;
    min-width: 0;
    line-height: normal;

    .send-to-iportal-modal-select-option-content-title {
      font-size: 14px;
      color: #222A35;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: normal;
    }

    .send-to-iportal-modal-select-option-content-desc {
      font-size: 12px;
      color: #909499;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: normal;
    }
  }
}

.send-to-iportal-modal-select-option-selected-icon {
  font-size: 16px;
  line-height: 1;
  color: #047FFE;
  display: none;
}
