/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-header {
  margin-left: 276px;
  padding: 0 26px;
  height: 60px;
  background-color: #fff;
}
.cooper-header .header-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cooper-header .item-right {
  float: right;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cooper-header .item-right :global .icon-user {
  margin-right: 0 !important;
  margin-left: 14px;
}
.cooper-header .item-right :global .v3-cooper-helper {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.cooper-header .item-right :global .v3-cooper-helper .dk-icon-a-bangzhuzhongxin4px {
  font-size: 20px;
}
.has-logo {
  margin-left: -5px;
}
.has-logo .logo {
  height: 36px;
}
