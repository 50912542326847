/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.batchMemberItem {
  height: 49px;
  cursor: pointer;
  width: calc(100% - 16px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1px 8px 0 8px;
}
.batchMemberItem:hover {
  border-radius: 6px;
  background-color: #F2F3F3;
}
.batchMemberItem-checkbox {
  margin-left: 8px !important;
  margin-right: 4px !important;
}
.batchMemberItem-checkbox .ant-checkbox-inner {
  border-radius: 3px !important;
}
.batchMemberItem .pic {
  margin-left: 8px;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.batchMemberItem .info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.batchMemberItem .info .left {
  max-width: 164px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.batchMemberItem .info .left .nameArea {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #222A35;
}
.batchMemberItem .info .left .nameArea .name {
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
}
.batchMemberItem .info .left .nameArea .tag {
  margin-left: 6px;
  padding: 1px 0;
  line-height: 16px;
  background: rgba(0, 102, 255, 0.08);
  border-radius: 3px;
  font-size: 12px;
  font-family: PingFangSC-Medium;
}
.batchMemberItem .info .left .nameArea .tag .word {
  display: block;
  color: #0066FF;
  transform-origin: center center;
  transform: scale(0.83333333);
}
.batchMemberItem .info .left .nameArea .tag-quit-self {
  background: #f4f4f5;
}
.batchMemberItem .info .left .nameArea .tag-quit-self .word {
  color: #666666;
}
.batchMemberItem .info .left .nameArea .tag-self {
  padding: 0 5px;
}
.batchMemberItem .info .left .nameArea .out-yellow {
  background-color: rgba(241, 139, 0, 0.1);
}
.batchMemberItem .info .left .nameArea .out-yellow .word {
  color: #F18B00;
}
.batchMemberItem .info .left .mail {
  font-size: 12px;
  color: #909499;
  line-height: 17px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.batchMemberItem .info .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 160px;
  width: max-content;
}
.batchMemberItem .info .right .tag {
  color: #909499;
  line-height: 16px;
  font-size: 12px;
  text-align: right;
  padding-right: 8px;
}
.batchMemberItem .info .right .single {
  text-align: right;
  margin-top: 4px;
  font-size: 20px;
  transform: scale(0.5);
  transform-origin: right;
  color: #999999;
  line-height: 12px;
  word-break: keep-all;
  width: fit-content;
  padding-right: 16px;
  white-space: nowrap;
}
.batchMemberItem .disabled {
  padding-right: 4px;
  color: #999999;
  background: none;
  cursor: not-allowed;
}
.batchMemberItem.quitMember {
  opacity: 0.5;
}
.batchMemberItem .dk-icon-guanbi1 {
  cursor: pointer;
  display: none;
}
.isSelected:hover .dk-icon-guanbi1 {
  cursor: pointer;
  font-size: 16px;
  width: 20px;
  height: 20px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7A7F86;
  margin-right: 8px;
}
.isSelected .info .left {
  max-width: 100% !important;
}
.isSelected .dk-icon-guanbi1:hover {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.08);
}
.disabled-not-allow {
  cursor: not-allowed !important;
}
.disabled-not-allow:hover {
  cursor: not-allowed !important;
}
.disabled-not-allow .name {
  color: #909499 !important;
}
