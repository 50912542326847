/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.user-perm-select-wrap {
  text-align: right;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  font-size: 14px;
  color: #666666;
}
.user-perm-select-wrap:hover {
  background-color: #E0E1E3;
}
.user-perm-select-wrap .powerChoose-content {
  text-align: right;
  border-radius: 4px;
  font-size: 13px;
  color: #4E555D;
  display: flex;
  align-items: center;
  justify-content: right;
}
.user-perm-select-wrap .powerChoose-content.choose-disabled {
  color: #909499;
}
.user-perm-select-wrap .powerChoose-content.choose-disabled .arrow-choose {
  display: none;
}
.user-perm-select-wrap .powerChoose-content .arrow-choose {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 4px;
}
.user-perm-select-wrap .powerChoose-visible .arrow-choose {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.user-perm-select-wrap .right-bottom {
  font-size: 10px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  padding-right: 20px;
  pointer-events: none;
  white-space: nowrap;
}
.user-perm-select-wrap-disable {
  cursor: default;
}
.user-perm-select-wrap-disable:hover {
  background-color: transparent;
}
.user-perm-select-wrap-disable .right-bottom {
  padding-right: 0;
}
.docs-cooperator-member-popover {
  width: 306px;
}
.docs-cooperator-member-popover .dropdown-content .recovery {
  background: rgba(4, 127, 254, 0.08);
  padding: 7px 8px !important;
  line-height: 17px;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  height: auto !important;
  margin-bottom: 1px;
}
.docs-cooperator-member-popover .dropdown-content .recovery:hover {
  background: rgba(4, 127, 254, 0.14) !important;
}
.docs-cooperator-member-popover .dropdown-content .recovery .recovery_label {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  text-align: justify;
}
.docs-cooperator-member-popover .dropdown-content .recovery .recovery_tip {
  cursor: pointer;
  position: absolute;
  right: 8px;
  font-size: 12px;
  margin-left: 11px;
  font-weight: 400;
  color: #047FFE;
  vertical-align: text-top;
}
.docs-cooperator-member-popover .dropdown-content .split-line {
  width: 100%;
  height: 1px;
  background-color: #EEEEEF;
  margin: 10px 0;
}
.docs-cooperator-member-popover .dropdown-content .option-remove {
  display: flex;
  width: 100%;
  font-size: 14px;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  color: #FF563B;
}
.docs-cooperator-member-popover .dropdown-content .option-remove:hover {
  background-color: #F2F3F3;
}
.docs-cooperator-member-popover .hide-member-perm-change .ant-popover-inner {
  display: none;
}
