/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.list-type {
  display: flex;
  margin-top: -4px;
  margin-bottom: 16px;
}
.list-type .list-type-item {
  padding: 4px 12px;
  border-radius: 4px;
  color: rgba(34, 42, 53, 0.9);
  margin-right: 6px;
}
.list-type .list-type-item:hover {
  background: #F2F3F3;
  cursor: pointer;
}
.list-type .tab-active {
  background: #E5F2FE;
  color: #047FFE;
}
.list-type .tab-active:hover {
  background: #E5F2FE;
  cursor: pointer;
}
