/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.modalTitleTip {
  max-width: 410px !important;
}
.modalTitleTip :global .ant-tooltip-arrow {
  display: none;
}
.modalTitleTip :global .ant-tooltip-inner {
  padding: 20px 24px !important;
  min-height: auto !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #666666 !important;
  background-color: #fff !important;
  line-height: 20px !important;
  border-radius: 6px !important;
}
.modalTitleTip .title {
  color: #333333 !important;
  margin-bottom: 10px;
}
.modalTitleTip .desc {
  margin-bottom: 12px !important;
}
.modalTitleTip .desc:last-child {
  margin-bottom: 0 !important;
}
.addTip {
  font-size: 24px;
  color: #666666;
  margin: 2px 0 0 5px;
}
