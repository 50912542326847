/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.switch-search-input {
  background-color: #F4f4f4 !important;
  height: 36px !important;
  line-height: 36px !important;
  border-radius: 4px !important;
  color: #222A35 !important;
  padding-top: 4px !important;
  font-size: 14px !important;
  padding-left: 12px !important;
  border: none !important;
}
.switch-search-input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.switch-search-icon {
  position: absolute;
  z-index: 20;
  top: 22px;
  left: 20px;
  color: #bbb;
}
.search-popover-content {
  padding: 4px 8px;
  width: 220px;
}
.search-popover-content ul {
  margin: 0 -8px;
  padding: 10px 8px;
  max-height: 180px;
  overflow: auto;
}
.search-popover-content ul li {
  padding: 8px 12px;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  cursor: pointer;
  border-radius: 4px;
}
.search-popover-content ul li:hover {
  background: #F7F8F9;
}
.search-popover-content ul li.active {
  background-color: rgba(11, 131, 255, 0.08);
  color: #0B83FF;
}
.search-popover-content ul li.search-item {
  display: flex;
  cursor: pointer;
}
.search-popover-content ul li.search-item span {
  flex: 1;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(34, 42, 53, 0.5);
}
.search-popover-content ul li.search-item span:first-child {
  flex: 1.6;
  font-weight: 500;
  color: #222A35;
}
.search-popover-content ul li.search-item span:last-child {
  padding-right: 0;
}
.search-popover-content .check-msg {
  color: #FE0B19;
  margin: 12px;
  font-size: 12px;
}
