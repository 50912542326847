/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.body-main-wrap {
  padding: 36px 52px 0px 52px;
  text-align: left;
}
.dc-body-main-wrap {
  padding: 15px 0 35px 28px;
}
.dc-body-main-wrap :global .ant-tabs-nav {
  margin-right: 28px !important;
}
.dc-body-main-wrap .mainTitle {
  margin-bottom: 13px;
}
.mainTitle {
  display: flex;
  margin-bottom: 16px;
}
.mainTitle .titleIcon {
  margin-top: 4px;
  margin-right: 12px;
  width: 32px;
  height: 32px;
  border-radius: 2px;
}
.mainTitle .title {
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #222A35;
  line-height: 28px;
}
.mainTitle .dk-menhu-title {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.page-detail-loading {
  position: relative;
}
