/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.collapse-panel-container .collapse-panel {
  position: relative;
}
.collapse-panel-container .collapse-panel :global .ant-collapse-item .ant-collapse-header {
  position: sticky;
  top: 0;
  width: 200px;
  padding: 11px 8px;
  margin: 0 12px;
  font-size: 13px;
  color: rgba(34, 42, 53, 0.5);
  line-height: 18px;
  background-color: #FFFFFF;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.collapse-panel-container .collapse-panel :global .ant-collapse-item .ant-collapse-header:hover {
  background-color: #F4F4F4;
}
.collapse-panel-container .collapse-panel :global .ant-collapse-item .ant-collapse-content-box {
  padding: 0;
}
.collapse-panel-container .collapse-panel :global .ant-collapse-item .ant-collapse-content-box .menu {
  padding: 0 12px;
}
.collapse-panel-container .collapse-panel :global .ant-collapse-item .ant-collapse-content-box .menu .option {
  width: 200px;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 14px;
  color: #222A35;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.collapse-panel-container .collapse-panel :global .ant-collapse-item .ant-collapse-content-box .menu .option.active {
  font-weight: 500;
  color: #047FFE;
  background: rgba(11, 131, 255, 0.08);
}
.collapse-panel-container .collapse-panel :global .ant-collapse-item .ant-collapse-content-box .menu .option:hover {
  background-color: #F4F4F4;
}
