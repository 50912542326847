/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.content-tip {
  cursor: pointer;
  color: #0066ff;
}
.content-title-weight {
  font-weight: bold;
}
.folder-tree {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
}
.folder-tree .checkbox-wrap {
  margin-right: 12px;
}
.folder-tree .ft-triangle-wrapper {
  height: 30px;
  width: 19px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.folder-tree .tb-body .tb-body-row-fileList:hover {
  background-color: #F3F3F3;
  border-radius: 4px;
}
.folder-tree .tb-header > .tb-header-div,
.folder-tree .tb-body .tb-body-row-fileList {
  height: 46px;
  line-height: 46px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: rgba(34, 42, 53, 0.7);
  padding-left: 4px;
}
.folder-tree .tb-header > .tb-header-div > span,
.folder-tree .tb-body .tb-body-row-fileList > span {
  display: inline-block;
}
.folder-tree .tb-header > .tb-header-div .file-name,
.folder-tree .tb-body .tb-body-row-fileList .file-name {
  min-width: 300px;
  max-width: initial;
  width: calc(100% - 448px);
  display: flex;
  align-items: center;
  overflow: hidden;
}
.folder-tree .tb-header > .tb-header-div .file-name .file-name-display,
.folder-tree .tb-body .tb-body-row-fileList .file-name .file-name-display {
  display: inline-block;
  flex: 1;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.folder-tree .tb-header > .tb-header-div .file-name > .ant-checkbox-wrapper,
.folder-tree .tb-body .tb-body-row-fileList .file-name > .ant-checkbox-wrapper {
  position: relative;
  top: -2px;
  margin-right: 18px;
}
.folder-tree .tb-header > .tb-header-div .file-resizer,
.folder-tree .tb-body .tb-body-row-fileList .file-resizer {
  width: 20px;
  margin-right: 8px;
  margin-left: 12px;
  position: relative;
  text-align: center;
}
.folder-tree .tb-header > .tb-header-div .file-resizer .resizer-line,
.folder-tree .tb-body .tb-body-row-fileList .file-resizer .resizer-line {
  width: 1px;
  height: 50px;
  background: #076BFF;
  position: absolute;
  top: 0;
  left: 50%;
}
.folder-tree .tb-header > .tb-header-div > .file-time,
.folder-tree .tb-body .tb-body-row-fileList > .file-time {
  width: 130px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.folder-tree .tb-header > .tb-header-div .file-owner,
.folder-tree .tb-body .tb-body-row-fileList .file-owner {
  width: 130px;
  margin-right: 30px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.folder-tree .tb-header > .tb-header-div > .file-owner + .file-time,
.folder-tree .tb-body .tb-body-row-fileList > .file-owner + .file-time {
  margin-right: 4px;
}
.folder-tree .tb-header > .tb-header-div > .file-operate,
.folder-tree .tb-body .tb-body-row-fileList > .file-operate {
  width: 114px;
  padding-right: 4px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.folder-tree .tb-header > .tb-header-div .triangle,
.folder-tree .tb-body .tb-body-row-fileList .triangle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 19px;
  margin-right: 10px;
  font-size: 16px;
  cursor: pointer;
  color: rgba(34, 42, 53, 0.7);
}
.folder-tree .tb-header > .tb-header-div .triangle-li,
.folder-tree .tb-body .tb-body-row-fileList .triangle-li {
  margin-left: 4px;
}
.folder-tree .is-open {
  font-size: 16px;
  transform: rotate(90deg);
}
.folder-tree .is-loading {
  font-size: 14px !important;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.folder-tree .tb-header {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  width: max-content;
  min-width: 100%;
  padding: 0 24px;
  display: flex;
}
.folder-tree .tb-header .tb-header-div {
  width: max-content;
  min-width: 100%;
  display: flex;
  height: 36px !important;
  line-height: 36px !important;
  border-bottom: 1px solid rgba(34, 42, 53, 0.08);
}
.folder-tree .tb-header .tb-header-div .file-operate,
.folder-tree .tb-header .tb-header-div .file-owner,
.folder-tree .tb-header .tb-header-div .file-time,
.folder-tree .tb-header .tb-header-div .file-name {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 22px;
}
.folder-tree .tb-header .tb-header-div .file-operate .ant-table-column-sorter .anticon-caret-up,
.folder-tree .tb-header .tb-header-div .file-owner .ant-table-column-sorter .anticon-caret-up,
.folder-tree .tb-header .tb-header-div .file-time .ant-table-column-sorter .anticon-caret-up,
.folder-tree .tb-header .tb-header-div .file-name .ant-table-column-sorter .anticon-caret-up,
.folder-tree .tb-header .tb-header-div .file-operate .ant-table-column-sorter .anticon-caret-down,
.folder-tree .tb-header .tb-header-div .file-owner .ant-table-column-sorter .anticon-caret-down,
.folder-tree .tb-header .tb-header-div .file-time .ant-table-column-sorter .anticon-caret-down,
.folder-tree .tb-header .tb-header-div .file-name .ant-table-column-sorter .anticon-caret-down {
  font-weight: bold;
}
.folder-tree .tb-header .tb-body-row-fileList.bottom-tip,
.folder-tree .tb-body .tb-body-row-fileList.bottom-tip {
  pointer-events: none;
  border: none;
  color: rgba(34, 42, 53, 0.7);
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 32px;
}
.folder-tree .tb-body {
  height: calc(100% - 40px);
  min-width: 100%;
  width: max-content;
  overflow: hidden;
  padding: 8px 24px 0;
}
.folder-tree .tb-body:hover {
  overflow: auto;
}
.folder-tree .tb-body .file-name {
  color: #222A35;
}
.folder-tree .tb-body-row-fileList {
  padding: 0 4px;
}
.folder-tree .tb-body-row-fileList:hover {
  border-radius: 4px;
  background: rgba(34, 42, 53, 0.06);
}
.folder-tree :global .ant-btn {
  min-width: 60px;
  width: auto;
  height: 32px;
  border: 1px solid #DDDDDD !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.folder-tree :global .ant-btn:hover,
.folder-tree :global .ant-btn:focus {
  border-color: #518dff;
  color: #518dff;
  background-color: #fff;
}
.folder-tree .loading-tip {
  margin-top: 14px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.folder-tree .loading-tip .text {
  margin-left: 4px;
}
.person-list .tb-header > .tb-header-div .file-name,
.person-list .tb-body .tb-body-row-fileList .file-name {
  width: calc(100% - 280px);
}
.person-list .tb-header > .tb-header-div > .file-operate,
.person-list .tb-body .tb-body-row-fileList > .file-operate {
  width: 80px;
  padding-right: 4px;
  display: flex;
  justify-content: right;
  align-items: center;
}
