/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.didoc-editor-help-center {
  position: relative;
  width: 42px;
  height: 42px;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border: 1px solid #E8EAED;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.didoc-editor-help-center .icon-bangzhuzhongxin {
  font-size: 20px;
  color: #6a707c;
}
.didoc-editor-help-center .options-popover {
  position: absolute;
  right: 100%;
  bottom: 0;
  list-style-type: none;
  margin: 0;
  padding: 8px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #EEEEEF;
  box-shadow: 0 4px 16px 0 rgba(60, 68, 88, 0.12);
  opacity: 0;
  visibility: hidden;
  transition: all 200ms;
}
.didoc-editor-help-center .options-popover .option-item {
  display: flex;
  align-items: center;
  height: 34px;
  border-radius: 4px;
  margin: 0;
  padding-right: 20px;
  white-space: nowrap;
}
.didoc-editor-help-center .options-popover .option-item .help-link {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  color: inherit;
}
.didoc-editor-help-center .options-popover .option-item:hover {
  background: #F2F3F3;
}
.didoc-editor-help-center .options-popover .option-item .iconfont {
  margin: 0 12px;
}
.didoc-editor-help-center .options-popover .option-item .option-title {
  font-size: 14px;
  line-height: 1;
}
.didoc-editor-help-center:hover .icon-bangzhuzhongxin {
  color: #047FFE;
}
.didoc-editor-help-center:hover .options-popover {
  opacity: 1;
  visibility: visible;
}
