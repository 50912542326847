// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.batch-modal {
  height: 630px !important;
  min-height: 630px !important;
  .ant-modal-content {
    width: 800px;
    min-width: 800px;
    border-radius: 8px;
    overflow: hidden;
    .ant-modal-header {
      padding: 20px 24px 12px !important;
      border-bottom: none !important;
    }
  }

  .ant-modal-body {
    padding: 0 !important;

    .batch-modal-content {
      border: 1px solid #EEEEEF;
      margin: 0 24px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;
      height: 494px;
      &-left {
        width: 432px;
        min-width: 432px;
        border-right: 1px solid #eee;
        padding: 12px 0;
        height: 494px;
        &-input {
          padding: 0 14px;
          margin-bottom: 10px;
          input {
            height: 24px;
            width: 100%;
            background: url('../icon/search.png') 0px 5px no-repeat;
            background-size: 16px 16px;
            padding-left: 20px;
            &::placeholder {
              color: #BDC0C3;
            }
          }
        }
        .list-wrap-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;
          padding: 0 10px 0 16px;
          margin-bottom: 4px;
          &-left {
            display: flex;
            align-items: center;
            &-checkbox {
              .ant-checkbox .ant-checkbox-inner {
                border-radius: 3px !important;
              } 
              span:last-child {
                padding: 0px;
              }
            }
            &-text {
              font-family: PingFang SC;
              color: #222A35;
              font-weight: 400;
              font-size: 14px;
              padding: 0 8px;
            }

            &-tips {
              font-size: 12px;
              color: #909499;
            }
          }

          &-right {
            &-content {
              cursor: pointer;
              height: 20px;
              padding: 0 4px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #656A72;
            }

            &:hover {
              background-color: #F2F3F3;
              color: #656A72;
              border-radius: 4px;
            }
          }
        }

        .list-wrap {
          height: 406px;
          min-height: 406px;
          overflow: auto;
          position: relative;
        }
        .search-list {
          height: 426px;
          min-height: 426px;
          overflow: auto;
          position: relative;
        }
      }

      &-right {
        width: 318px !important;
        min-width: 318px !important;
        padding: 12px 8px;
        height: 494px;
        .list-wrap-title {
          height: 16px;
          line-height: 14px;
          font-size: 12px;
          color: #999;
          padding: 0 8px;
        }
        .list-wrap {
          height: 448px;
          min-height: 448px;
          overflow: auto;
          position: relative;
        }
      }
    }

    .batch-modal-footer {
      padding: 0 24px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-left {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #222A35;
        font-size: 14px;
        .dropdown-checkbox {
          width: 260px;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-btn {
          margin-left: 12px;
          &:first-child:hover {
            background-color: #F2F3F3;
            border-color: rgba(34, 42, 53, 0.08);
            color: #222A35;
          }
        }
      }
    }
  }
}

.list-wrap-title-popover {
  padding-bottom:  0px !important;
  .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    padding: 16px !important;
    border: 1px solid #f2f3f3;
    border-radius: 6px !important;
  }
}

.popoverContent {
  font-size: 14px;
  color: #222A35;
  .link {
    font-weight: 500;
    cursor: pointer;
    color: #047FFE;
  }
  .dk-iconfont {
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
  }
}
.list-wrap {
  .ant-spin {
    padding-top: 200px;
    width: 386px;
  }
}

.remove-member {
  color: #FF563B;
}

.ant-message-warning {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-popover-content {
  width: 360px;
  padding: 10px;
  &-title {
    margin-bottom: 16px;
    color: #222a35;
    display: flex;
    .dk-icon-a-tishi2 {
      color: #ffa50c;
      margin-right: 8px;
      margin-top: 2px;
    }
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      margin-left: 10px;
    }
  }
}