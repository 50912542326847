/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.transfer-owner-modal {
  z-index: 1040 !important;
}
.transfer-owner-modal :global .ant-modal {
  width: 640px !important;
}
.transfer-owner-modal :global .ant-modal .ant-modal-header {
  margin-bottom: 8px;
  padding: 0;
  border-bottom: none;
}
.transfer-owner-modal :global .ant-modal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  font-weight: 500;
  color: #2F343C;
  line-height: 28px;
}
.transfer-owner-modal .apply-body-desc {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
}
.transfer-owner-modal .blank-content {
  height: 201px;
}
.transfer-owner-modal .transfer-cancel-btn:hover {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
}
.transfer-owner-modal .transfer-ok-btn,
.transfer-owner-modal .transfer-ok-btn:hover {
  color: #fff;
  background: #047FFE;
  border-color: #047FFE;
}
.transfer-owner-modal .transfer-ok-btn[disabled],
.transfer-owner-modal .transfer-ok-btn:hover[disabled] {
  opacity: 0.5;
}
