/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.share-link {
  display: flex;
  margin-top: 10px;
}
.share-link .link {
  flex: 1;
  height: 36px;
  line-height: 36px;
  background: #F7F8F9;
  border-radius: 6px;
  overflow-x: auto;
  padding: 0 16px;
  color: #2F343C;
  white-space: nowrap;
  border: none;
  outline: none;
}
.share-link .link::-webkit-scrollbar {
  display: none;
}
.share-link .preview-link {
  min-width: 60px;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  background: rgba(4, 127, 254, 0.1);
  color: #047FFE;
  border-radius: 4px;
  margin-left: 10px;
  border: rgba(4, 127, 254, 0.1);
  text-align: center;
  cursor: pointer;
}
.share-link .preview-link:focus {
  outline: none;
  background: rgba(4, 127, 254, 0.1);
  color: #047FFE;
  border: rgba(4, 127, 254, 0.1);
}
.share-link .preview-link:hover {
  background: rgba(4, 127, 254, 0.1);
  color: #047FFE;
  border: rgba(4, 127, 254, 0.1);
}
.share-link .copy-button {
  width: 88px;
  height: 36px;
  border-radius: 4px;
  background-color: #0B83FF;
  border: #0B83FF;
  font-size: 14px;
  margin-left: 8px;
  color: #ffffff;
}
.share-link .copy-button:focus {
  outline: none;
  background: #0B83FF;
  color: #ffffff;
  border: #0B83FF;
}
.share-link .copy-button:hover {
  background: #0B83FF;
  color: #ffffff;
  border: #0B83FF;
}
