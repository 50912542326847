/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.memberItem {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 12px;
  margin-bottom: 2px;
  cursor: pointer;
}
.memberItem:hover {
  background: #EFF0F2;
}
.memberItem .avatar {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 12px;
}
.memberItem .info {
  flex: 1;
}
.memberItem .info .existTag {
  margin-left: 4px;
  display: inline-block;
  width: 50px;
  height: 19px;
  background: #F5F5F6;
  border-radius: 2px;
  font-size: 12px;
  color: #666666;
  line-height: 19px;
  text-align: center;
  transform-origin: center center;
  transform: scale(0.83333333);
}
.memberItem .name-wrap {
  font-size: 14px;
  font-weight: 400;
  color: #111111;
  line-height: 20px;
}
.memberItem .name-wrap.online {
  margin: 0;
}
.memberItem .name-wrap .name {
  vertical-align: bottom;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 390px;
}
.memberItem .name-wrap .number {
  line-height: 20px;
  vertical-align: bottom;
}
.memberItem .from {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
}
.memberItem .department {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.memberItem .nameLen {
  max-width: 80px !important;
}
.memberItem.name-flex .info {
  width: 210px;
}
.memberItem.name-flex .name-wrap {
  display: flex;
  align-items: center;
}
.memberItem.name-flex .name {
  display: block;
  flex-shrink: 0;
}
.memberItem.name-flex .existTag {
  display: block;
  flex-shrink: 0;
  color: #0066FF;
  background: rgba(0, 102, 255, 0.08);
  border-radius: 4px;
}
