/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.add-auth-members :global .ant-modal-header {
  border-bottom: none;
  border-radius: 8px;
  padding: 20px 24px 14px 24px;
}
.add-auth-members :global .ant-modal-header .ant-modal-title {
  font-size: 20px;
  line-height: 28px;
}
.add-auth-members :global .ant-modal-content {
  padding: 0;
  border-radius: 6px;
}
.add-auth-members :global .ant-modal-body {
  padding: 0 24px 24px;
}
.add-auth-members .addMember .search-input {
  width: 100%;
  height: 36px;
  line-height: 36px;
  border-radius: 6px;
  border: 1px solid rgba(34, 42, 53, 0.2);
  background: url('./search.png') 12px center no-repeat;
  background-size: 18px 18px;
  padding-left: 32px;
  font-size: 14px;
  margin-bottom: 14px;
}
.add-auth-members .addMember .search-input:focus {
  border: 0.5px solid #7EB2FF;
  box-shadow: 0 0 0 1.5px rgba(8, 143, 252, 0.1);
}
.add-auth-members .addMember .memberList-title {
  margin: 5px 0 16px;
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #2F343C;
  line-height: 22px;
}
.add-auth-members .addMember .footer {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-auth-members .addMember .footer .num {
  font-size: 14px;
  color: #2F343C;
}
.add-auth-members .addMember .footer .btnArea {
  display: flex;
}
.add-auth-members .addMember .footer .btnArea .btn {
  width: 90px;
  height: 34px;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
}
.add-auth-members .addMember .footer .btnArea .btn.cancel {
  cursor: pointer;
  margin-right: 12px;
  color: #333333;
  border: 1px solid #DDDDDD;
}
.add-auth-members .addMember .footer .btnArea .btn.ant-btn-clicked:after {
  display: none;
}
.add-auth-members .addMember .footer .btnArea .btn.confirm {
  background: #333333;
  color: #FFFFFF;
  border: none;
}
.add-auth-members .addMember .footer .btnArea .btn.confirm[disabled] {
  background: #AAAAAA;
}
.add-auth-members .addMember .searchResult {
  height: 278px;
  position: relative;
}
