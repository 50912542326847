/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.page-detail {
  position: relative;
  height: 100%;
  background: #FFFFFF;
  line-height: 1;
  overflow-x: auto;
  min-width: 600px;
}
.page-detail .page-content {
  position: relative;
  height: calc(100% - 52px);
  padding-top: 0;
  transition: all 0.3s;
}
.page-detail .page-content :global .didoc-editor-app .editor {
  min-height: calc(100vh - 328px);
}
.page-detail .page-content :global .full-screen {
  padding-bottom: 0;
  width: 100% !important;
}
.page-detail .page-content-book {
  height: calc(100% - 60px);
}
.page-detail .no-header {
  height: 100%;
}
.page-detail .no-header :global .didoc-editor-app .editor {
  min-height: calc(100vh - 197px);
}
.page-detail-inphone {
  min-width: unset;
}
.authority {
  height: 100%;
}
.no-permission-tip {
  font-size: 14px;
  font-weight: 500;
  line-height: 33px;
}
.fullComment-tip {
  border-radius: 35px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 19;
  bottom: 35px;
  right: 20px;
  padding: 11px;
  cursor: pointer;
}
.fullComment-tip .tip-img {
  width: 18px;
  flex: 0 0 18px;
  opacity: 0.8;
}
.didoc-editor-app .editor .didoc-editor-container {
  padding: 0 56px 15px;
}
.view-document-detail :global .view-document-detail-header,
.view-share-document-detail :global .view-document-detail-header {
  display: none;
}
.view-document-detail :global .utils-drawer,
.view-share-document-detail :global .utils-drawer {
  display: none;
}
.view-document-detail :global .wiki-editor-container-update-info,
.view-share-document-detail :global .wiki-editor-container-update-info {
  display: none;
}
.view-document-detail :global #didoc-editor-slot-sub-title,
.view-share-document-detail :global #didoc-editor-slot-sub-title {
  display: none;
}
.view-document-detail :global #didoc-editor-slot-after,
.view-share-document-detail :global #didoc-editor-slot-after {
  display: none;
}
.view-document-detail :global .didoc-editor-container,
.view-share-document-detail :global .didoc-editor-container {
  padding: 0 8px 80px 8px !important;
}
.view-document-detail :global .didoc-editor-content-container,
.view-share-document-detail :global .didoc-editor-content-container {
  padding-right: 0 !important;
}
.view-document-detail :global .didoc-editor-content-container .code_block:first-child,
.view-share-document-detail :global .didoc-editor-content-container .code_block:first-child {
  margin-top: 15px;
}
.view-document-detail :global .didoc-editor-content-container .expand-container:first-child,
.view-share-document-detail :global .didoc-editor-content-container .expand-container:first-child {
  margin-top: 15px;
}
.view-document-detail :global .didoc-editor-content-container .didoc-editor-file-containter:first-child,
.view-share-document-detail :global .didoc-editor-content-container .didoc-editor-file-containter:first-child {
  margin-top: 48px!important;
}
