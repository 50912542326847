/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.emptySearch {
  text-align: center;
}
.emptySearch-icon {
  margin: 80px auto 12px;
  width: 128px;
  height: 115px;
}
.emptySearch-tip {
  height: 20px;
  font-size: 14px;
  color: #505050;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
}
