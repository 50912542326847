/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.star-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 12px;
}
.star-trigger:hover {
  background: #ECEDF0;
}
.star-trigger .star-button {
  font-size: 18px;
  color: #6A707C;
}
.star-trigger .hasStar {
  color: #f6c746;
}
