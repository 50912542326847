/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.switch-search-input {
  background-color: #F4f4f4 !important;
  height: 36px !important;
  line-height: 36px !important;
  border-radius: 4px !important;
  color: #222A35 !important;
  padding-top: 4px !important;
  font-size: 14px !important;
  padding-left: 12px !important;
  border: none !important;
}
.switch-search-input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.switch-search-icon {
  position: absolute;
  z-index: 20;
  top: 22px;
  left: 20px;
  color: #bbb;
}
.search-popover-content {
  color: #222A35;
  padding-top: 4px;
}
.search-popover-content ul {
  margin: 0 -8px;
  padding: 10px 8px;
  max-height: 420px;
  overflow: auto;
}
.search-popover-content ul li {
  padding: 8px 12px;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
}
.search-popover-content ul li:hover {
  background: #F7F8F9;
}
.search-popover-content ul li.active {
  background-color: rgba(11, 131, 255, 0.08);
  color: #0B83FF;
}
.search-popover-content .check-msg {
  color: #FE0B19;
  margin: 12px;
  font-size: 12px;
}
.check-icon {
  font-size: 16px;
  margin-right: 4px;
  color: #047FFE;
  margin-left: 4px;
}
.active {
  color: #047FFE;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.9);
}
