/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.file-name-list {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  object-fit: cover;
  object-position: left center;
  overflow: hidden;
  display: inline-block;
  line-height: 1;
}
.file-name-list svg {
  max-width: 100%;
  max-height: 100%;
}
.file-content-wrap {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px 8px 8px 10px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-content-wrap:hover {
  background-color: rgba(34, 42, 53, 0.04);
}
.file-content-wrap :global .file-icon {
  width: 32px;
  height: 32px;
}
.file-content-wrap :global .file-text-main {
  margin-left: 12px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-content-wrap :global .file-text-main .title {
  color: #222A35;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.file-content-wrap :global .file-text-main .title .title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-content-wrap :global .file-text-main .description {
  margin-top: 2px;
  color: rgba(34, 42, 53, 0.7);
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-content-wrap :global .file-text-main .addition {
  color: rgba(34, 42, 53, 0.5);
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
}
.file-content-wrap :global .file-text-main .addition .item-div {
  display: flex;
  align-items: center;
}
.file-content-wrap :global .file-text-main .addition .item-div .item-span {
  padding-right: 8px;
}
.file-content-wrap :global .file-text-main .addition .metis-name {
  color: rgba(34, 42, 53, 0.5);
  line-height: 20px;
}
.file-content-wrap :global .file-text-main .addition .metis-name .metis-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  display: inline-block;
  color: rgba(34, 42, 53, 0.5);
  max-width: 160px;
}
.file-content-wrap :global .file-text-main .addition .metis-name .metis-span:hover {
  color: #047FFE;
}
.file-content-wrap :global .file-text-main .addition .metis-name .scope-icon {
  font-size: 12px;
  line-height: 20px;
  margin-right: 2px;
}
.file-content-wrap :global .file-text-main .addition .owner {
  line-height: 20px;
}
.file-content-wrap :global .file-text-main .addition .owner .owner-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 120px;
}
.file-content-wrap :global .file-text-main .addition .publish {
  flex-shrink: 0;
}
.file-content-wrap :global .file-text-main .addition div:first-child {
  margin-left: 0;
}
.file-content-wrap :global .file-text-main .addition div:last-child {
  margin-left: 0;
}
.file-content-wrap :global .file-text-main .addition div:last-child::after {
  content: "";
}
.file-content-wrap :global .file-text-main .addition > div:after {
  content: "|";
  color: #D8DEE3;
  position: relative;
  bottom: 1px;
  width: 9px;
  height: 20px;
}
.file-content-wrap-large .file-text-main {
  width: calc(100% - 80px);
}
.no-padding-left {
  padding-left: 0px;
  padding-right: 0px;
}
.no-padding-left:hover {
  background: none;
}
