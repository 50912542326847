/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.label-item {
  margin-bottom: 20px;
}
.label-item .label-text {
  font-size: 14px;
  font-weight: 500;
  color: #222A35;
  margin-bottom: 8px;
}
.label-item .label-text .curr-perm {
  color: #047FFE;
  margin: 0 2px;
}
.label-item .label-text .require {
  color: #FF563B;
  margin-left: 2px;
}
.label-item .apply-input {
  border-radius: 4px;
  height: 96px;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid rgba(34, 42, 53, 0.2);
  color: #222A35;
}
.label-item .apply-input:hover {
  border: 1px solid #047FFE;
}
.label-item .apply-input:focus {
  border: 1px solid #047FFE;
}
.link {
  color: #047FFE;
  cursor: pointer;
}
.link:hover {
  color: #047FFE;
}
.icon-close {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
  color: rgba(34, 42, 53, 0.5);
  position: relative;
  top: 2px;
}
.footer-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-wrap .explanation {
  font-size: 12px;
  color: rgba(34, 42, 53, 0.5);
}
.footer-wrap .explanation:hover {
  color: #047FFE;
}
.footer-wrap .btn-content button:nth-child(1) {
  margin-left: 12px !important;
}
