/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 20px 24px;
  overflow: hidden;
}
.header-title .title-content {
  flex: 1;
  display: flex;
  align-items: center;
}
.header-title .search-content {
  width: 540px;
  min-width: 308px;
  margin-left: 20px;
}
