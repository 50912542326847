/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.list-wrap .emptyInfo {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  height: 148px;
  font-size: 14px;
  color: #666666;
}
.list-wrap .emptyInfo .pic {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 9px;
}
