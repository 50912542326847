/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.inherit-set-btn {
  padding: 5px 8px;
  border-radius: 4px;
  max-width: 200px;
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  border: 1px solid rgba(0, 102, 255, 0.7);
  background: url('../../icon/inherit.png') center center no-repeat;
  background-size: 100%;
}
.inherit-set-btn .set-icon {
  font-size: 14px;
  margin-right: 4px;
  color: #222A35;
}
.inherit-set-btn .set-text {
  font-size: 12px;
  color: #222A35;
  line-height: 17px;
  margin-right: 12px;
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.disabled {
  cursor: not-allowed;
}
.disabled:hover {
  opacity: 0.4;
}
.inherit-set-wrap {
  padding: 10px;
}
.inherit-set-wrap .inherit-content {
  width: 100%;
}
.inherit-set-wrap .inherit-content .container {
  cursor: pointer;
  width: 260px;
  border-radius: 6px;
  border: 1px solid #E8E9EA;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 13px 18px;
  margin-bottom: 10px;
}
.inherit-set-wrap .inherit-content .container .container-left {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.inherit-set-wrap .inherit-content .container .container-right {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.inherit-set-wrap .inherit-content .container .container-right .right-title {
  font-size: 15px;
  font-weight: 500;
  color: #222A35;
  line-height: 16px;
  margin-bottom: 5px;
}
.inherit-set-wrap .inherit-content .container .container-right .right-tip {
  font-size: 12px;
  color: #909499;
  line-height: 16px;
}
.inherit-set-wrap .inherit-content .change-container-inherit {
  border: 1px solid rgba(0, 102, 255, 0.7);
  background: url('../../icon/inherit.png') center center no-repeat;
  background-size: 100%;
}
.inherit-set-wrap .inherit-content .change-container-noninherit {
  border: 1px solid rgba(0, 102, 255, 0.7);
  background: url('../../icon/non_inheritance.png') center center no-repeat;
  background-size: 100%;
}
.inherit-set-wrap .container-footer {
  cursor: pointer;
  color: #656A72;
  line-height: 16px;
  font-size: 12px;
  padding: 6px 8px;
  display: inline-block;
  border-radius: 4px;
}
.inherit-set-wrap .container-footer:hover {
  background: #F2F3F3;
}
.inherit-set-wrap .container-footer .arrow-perm {
  font-size: 12px;
  margin-left: 2px;
  color: #656A72;
}
