/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.change-page-owner-wrap {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
}
.change-page-owner-wrap .change-page-owner {
  text-align: center;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  font-weight: 400;
  color: #047FFE;
  cursor: pointer;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.change-page-owner-wrap .change-page-owner .owner-change-icon {
  font-size: 14px;
  margin-right: 2px;
}
.change-page-owner-wrap .change-page-owner .owner-change-text {
  font-size: 12px;
  display: inline-block;
}
