/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.choose-member-perm {
  width: 100%;
  height: 36px;
  border: 1px solid rgba(34, 42, 53, 0.2);
  border-radius: 4px;
  background: #FFFFFF;
  cursor: pointer;
}
.choose-member-perm:hover {
  border: 1px solid #047FFE;
}
.choose-member-perm .choosed-perm {
  color: #222A35;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 12px;
}
.choose-member-perm .choosed-perm .choosed-perm-text {
  font-size: 14px;
}
.choose-member-perm .choosed-perm .choosed-perm-icon {
  display: inline-block;
  transform: rotate(90deg);
  color: #333;
}
.choose-member-perm .choosed-perm .icon-up {
  transform: rotate(270deg);
}
.choose-member-perm .placeholder {
  color: rgba(34, 42, 53, 0.4);
}
.active {
  border: 1px solid #047FFE;
}
.member-perm-popover :global .ant-popover-inner {
  border-radius: 8px;
  width: 300px;
}
.member-perm-popover :global .ant-popover-inner-content {
  padding: 8px;
}
.member-perm-popover-small :global .ant-popover-inner {
  width: 160px;
}
.line-multi {
  height: 1px;
  background: rgba(34, 42, 53, 0.08);
  margin: 8px 0 10px 0;
}
