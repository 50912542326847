/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.version-list {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 296px;
  background: #F7F8F9;
  border-right: 1px solid #E8EAED;
}
.version-list .restoretoVersion {
  width: 92px;
  height: 29px;
  display: inline-block;
  background: #F4F5F6;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #047FFE;
  line-height: 29px;
}
.version-list .restoretoVersion:hover {
  background: #E8F3FF;
}
.version-list .restoreBlock {
  display: block;
}
.version-list .restoreNone {
  display: none;
}
.version-list .layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.version-list .layout .header .title {
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
  line-height: 22px;
  padding: 17px 21px 21px;
}
.version-list .layout .body {
  flex: 1;
  overflow: auto;
  position: relative;
}
.version-list .layout .body .content .card {
  width: 272px;
  height: 98px;
  background: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  margin: auto;
}
.version-list .layout .body .content .card + .card {
  margin-top: 12px;
}
.version-list .layout .body .content .card.is-active {
  box-shadow: 0 6px 22px 0 rgba(0, 0, 0, 0.14);
}
.version-list .layout .body .content .card.is-active .container {
  border-left: 2px solid #047FFE;
}
.version-list .layout .body .content .card .container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 2px solid #D9DBDC;
  padding: 0 10px;
}
.version-list .layout .body .content .card .container .title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #222A35;
  line-height: 20px;
  margin-top: 10px;
  height: 29px;
}
.version-list .layout .body .content .card .container .title .badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid rgba(253, 116, 67, 0.4);
  font-size: 12px;
  font-weight: 500;
  color: #FD7443;
  margin-left: 10px;
}
.version-list .layout .body .content .card .container .titleJustify {
  justify-content: space-between;
}
.version-list .layout .body .content .card .container .datetime {
  font-size: 12px;
  color: rgba(34, 42, 53, 0.4);
  line-height: 17px;
  margin-top: -2px;
}
.version-list .layout .body .content .card .container .username {
  font-size: 12px;
  color: #707070;
  line-height: 17px;
  margin-top: 8px;
}
.version-list .layout .emptyPage {
  width: 148px;
  position: absolute;
  left: 50%;
  top: 268px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.version-list .layout .emptyPage .emptyImg {
  width: 100%;
  height: 148px;
}
.version-list .layout .emptyPage .emptyInfo {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 6px;
}
.revert-version-modal {
  padding-bottom: 0;
}
.revert-version-modal :global .ant-modal-content {
  border-radius: 6px;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body {
  padding: 32px 28px 24px;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  font-size: 24px;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
  margin-top: 36px;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  width: 96px;
  height: 44px;
  background: #F6F6F6;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  color: #444B4F;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn::after {
  display: none;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  margin-left: 16px;
  color: #FFFFFF;
  background: #047FFE;
}
.revert-version-modal .comparisonVersion .ant-cascader-picker-disabled .ant-cascader-input {
  border: none !important;
  color: #252525 !important;
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  font-weight: 400 !important;
}
.revert-version-modal .comparisonVersion .ant-input {
  border: none;
}
.revert-version-modal .tip-content {
  font-size: 16px;
  color: #444B4F;
  line-height: 24px;
  margin-top: 10px;
}
.revert-version-modal .tip-content .version {
  color: #444B4F;
  font-weight: 500;
}
.revert-version-modal .tip-content .link {
  color: #047FFE;
}
