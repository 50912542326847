/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.send-to-iportal-modal {
  width: 500px!important;
  border-radius: 8px;
  padding-bottom: 0!important;
  overflow: hidden;
}
.send-to-iportal-modal :global .ant-modal-content .ant-modal-body {
  padding: 12px 0;
}
.send-to-iportal-modal-content {
  display: flex;
  flex-direction: column;
}
.send-to-iportal-modal-content .send-to-iportal-modal-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
}
.send-to-iportal-modal-content .send-to-iportal-modal-header .send-to-iportal-modal-header-logo {
  width: 20px;
  margin-right: 5px;
}
.send-to-iportal-modal-content .send-to-iportal-modal-header .send-to-iportal-modal-header-text {
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main {
  flex: 1;
  max-height: calc(100vh - 210px);
  display: flex;
  flex-direction: column;
  padding: 12px 0 6px 24px;
  gap: 24px;
  overflow: auto;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section {
  width: 452px;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #222A35;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-title::after {
  content: ' *';
  color: #FF563B;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content {
  display: flex;
  gap: 12px;
  margin-top: 8px;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-radio-group {
  display: flex;
  align-items: center;
  gap: 30px;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-radio-group .send-to-iportal-modal-radio-item {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #222A35;
  cursor: pointer;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-radio-group .send-to-iportal-modal-radio-item .send-to-iportal-modal-selected-icon {
  margin-right: 6px;
  font-size: 18px;
  color: #a7aaae;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-radio-group .send-to-iportal-modal-radio-item.is-active,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-radio-group .send-to-iportal-modal-radio-item:hover {
  border-color: rgba(4, 127, 254, 0.6);
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-radio-group .send-to-iportal-modal-radio-item.is-active .send-to-iportal-modal-selected-icon,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-radio-group .send-to-iportal-modal-radio-item:hover .send-to-iportal-modal-selected-icon {
  color: #047FFE;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-left,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-right {
  display: flex;
  gap: 6px;
  width: 220px;
  min-height: 63px;
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #D3D4D7;
  cursor: pointer;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-left .send-to-iportal-modal-selected-icon,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-right .send-to-iportal-modal-selected-icon {
  font-size: 18px;
  color: #A7AAAE;
  margin-top: 3px;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-left.is-active,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-right.is-active,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-left:hover,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-right:hover {
  border-color: rgba(4, 127, 254, 0.6);
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-left.is-active .send-to-iportal-modal-selected-icon,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-right.is-active .send-to-iportal-modal-selected-icon,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-left:hover .send-to-iportal-modal-selected-icon,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-right:hover .send-to-iportal-modal-selected-icon {
  color: #047FFE;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-left.is-active,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-right.is-active {
  background: url("../../assets/icon/iportal_bg.png") no-repeat right bottom / contain;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-summary .send-to-iportal-modal-main-content-summary-title {
  font-size: 14px;
  color: #222A35;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-content .send-to-iportal-modal-main-content-summary .send-to-iportal-modal-main-content-summary-desc {
  font-size: 12px;
  font-weight: normal;
  color: #909499;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-panel {
  padding: 12px;
  border-radius: 6px;
  background: #F4F7F8;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-panel .send-to-iportal-modal-main-notice {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #222A35;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-panel .send-to-iportal-modal-main-notice.is-active .send-to-iportal-modal-main-notice-icon,
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-panel .send-to-iportal-modal-main-notice:hover .send-to-iportal-modal-main-notice-icon {
  color: #047FFE;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-panel .send-to-iportal-modal-main-notice .send-to-iportal-modal-main-notice-icon {
  font-size: 18px;
  color: #A7AAAE;
  cursor: pointer;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-panel .send-to-iportal-modal-main-notice-list {
  margin: 8px 0 0 2px;
  padding: 0;
  font-size: 12px;
  color: #656A72;
}
.send-to-iportal-modal-content .send-to-iportal-modal-main .send-to-iportal-modal-main-section .send-to-iportal-modal-main-panel .send-to-iportal-modal-main-notice-list-item {
  list-style-type: decimal;
  list-style-position: inside;
  margin-top: 4px;
  line-height: normal;
}
.send-to-iportal-modal-content .send-to-iportal-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 24px 12px;
}
.send-to-iportal-modal-content .send-to-iportal-modal-footer .send-to-iportal-modal-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 32px;
  border-radius: 3px;
  border: 1px solid #EEEEEF;
  outline: none;
  font-size: 14px;
  color: #222A35;
  cursor: pointer;
  background: #fff;
}
.send-to-iportal-modal-content .send-to-iportal-modal-footer .send-to-iportal-modal-cancel:hover {
  background: #F2F3F3;
}
.send-to-iportal-modal-content .send-to-iportal-modal-footer .send-to-iportal-modal-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 32px;
  font-size: 14px;
  outline: none;
  border-radius: 4px;
  background: #047FFE;
  border: none;
  cursor: pointer;
  color: #fff;
}
.send-to-iportal-modal-content .send-to-iportal-modal-footer .send-to-iportal-modal-confirm:hover {
  background: rgba(4, 127, 254, 0.8);
}
.send-to-iportal-modal-content .send-to-iportal-modal-footer .send-to-iportal-modal-confirm:disabled {
  background: rgba(4, 127, 254, 0.4);
  cursor: not-allowed;
}
.send-to-iportal-modal-content .send-to-iportal-modal-footer .send-to-iportal-modal-confirm.is-loading {
  background: rgba(4, 127, 254, 0.4);
}
.send-to-iportal-modal-content .send-to-iportal-modal-footer .send-to-iportal-modal-confirm .send-to-iportal-modal-confirm-icon {
  margin-right: 6px;
}
.send-to-iportal-modal-close-btn {
  position: absolute;
  right: 24px;
  top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: #7A7F86;
}
.send-to-iportal-modal-close-btn .send-to-iportal-modal-close-icon {
  font-size: 20px;
}
.send-to-iportal-modal-close-btn:hover {
  background: #F2F3F3;
}
.send-to-iportal-modal-select {
  width: 100%!important;
}
.send-to-iportal-modal-select:global.ant-select-focused:not(.ant-select-open) .ant-select-selector {
  border-color: #d3d4d7 !important;
}
.send-to-iportal-modal-select:global.ant-select-disabled .ant-select-selector:hover {
  border-color: #d3d4d7 !important;
}
.send-to-iportal-modal-select :global .ant-select-selector {
  height: 36px!important;
  align-items: center;
  border-radius: 4px!important;
  border: 1px solid #D3D4D7 !important;
}
.send-to-iportal-modal-select :global .ant-select-selection-item {
  line-height: 1;
  display: block;
  font-size: 14px;
  color: #222A35 !important;
}
.send-to-iportal-modal-select .send-to-iportal-modal-select-suffix-icon {
  color: #333333;
}
.send-to-iportal-modal-select-empty {
  text-align: center;
  font-size: 12px;
  color: #656A72;
}
.send-to-iportal-modal-select-option {
  border-radius: 6px!important;
  border: 1px solid #E8E9EA;
  box-shadow: 0px 6px 24px -3px rgba(0, 0, 0, 0.12);
}
.send-to-iportal-modal-select-option :global .ant-select-item {
  padding: 6px 12px 6px 8px!important;
  border-radius: 6px;
  background: #fff !important;
}
.send-to-iportal-modal-select-option :global .ant-select-item.ant-select-item-option-active {
  background: #F2F3F3 !important;
}
.send-to-iportal-modal-select-option :global .ant-select-item.ant-select-item-option-selected :local .send-to-iportal-modal-select-option-selected-icon {
  display: block;
}
.send-to-iportal-modal-select-option-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.send-to-iportal-modal-select-option-item .send-to-iportal-modal-select-option-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.send-to-iportal-modal-select-option-item .send-to-iportal-modal-select-option-content {
  flex: 1;
  min-width: 0;
  line-height: normal;
}
.send-to-iportal-modal-select-option-item .send-to-iportal-modal-select-option-content .send-to-iportal-modal-select-option-content-title {
  font-size: 14px;
  color: #222A35;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
}
.send-to-iportal-modal-select-option-item .send-to-iportal-modal-select-option-content .send-to-iportal-modal-select-option-content-desc {
  font-size: 12px;
  color: #909499;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
}
.send-to-iportal-modal-select-option-selected-icon {
  font-size: 16px;
  line-height: 1;
  color: #047FFE;
  display: none;
}
