/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.user-item-right {
  text-align: right;
  cursor: pointer;
  padding: 5px 0px 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  color: #666666;
}
.user-item-right:hover {
  background-color: #E0E1E3;
}
.user-item-right .user-item-right-dropdown {
  width: auto;
  height: auto;
  display: flex;
  min-width: 0;
}
.user-item-right .user-item-right-dropdown :global .dropdown-checkbox__value {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  color: #505050;
}
.user-item-right .user-item-right-dropdown :global .dropdown-checkbox__value .dropdown-checkbox__caret {
  height: 17px;
  right: 4px;
}
.user-item-right .user-item-right-dropdown :global .dropdown-checkbox__value span {
  height: auto;
  line-height: 18px;
  padding: 0 20px 0 0;
  font-size: 12px;
}
.user-item-right .right-bottom {
  font-size: 10px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  padding-right: 20px;
  pointer-events: none;
  white-space: nowrap;
}
