/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.convert-flex {
  display: flex;
  justify-content: center;
}
.convert-des {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0px;
  color: #222A35;
}
.convert img {
  width: 120px;
}
.convert :global .ant-progress-bg {
  transition: all 120s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.convert-footer-center :global .ant-modal-footer {
  text-align: center;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
}
.cooper-confirm .ant-modal-body {
  padding: 0;
}
.cooper-confirm :global .ant-modal-content {
  border-radius: 8px !important;
}
.cooper-confirm :global .ant-modal-body {
  padding: 0 24px;
}
.cooper-confirm :global .ant-modal-body .ant-modal-confirm-title {
  padding-right: 14px;
}
.cooper-confirm :global .ant-modal-header {
  border-radius: 8px 8px 0 0 !important;
  padding: 32px 32px 16px;
  border-bottom: 1px solid transparent;
}
.cooper-confirm :global .ant-modal-footer {
  border-top: 1px solid transparent;
  padding: 20px 24px 24px;
}
.cooper-confirm :global .ant-modal-close .ant-modal-close-x {
  width: 24px;
  height: 24px;
  margin-top: 24px;
  margin-right: 24px;
}
.cooper-confirm :global .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cooper-confirm :global .ant-modal-close .ant-modal-close-x .ant-modal-close-icon:hover {
  background-color: #F2F3F3;
  border-radius: 4px;
}
.cooper-confirm :global .dk-iconfont {
  font-size: 28px;
}
.cooper-confirm :global .dk-iconfont:hover {
  background-color: #EBEEF1;
  border-radius: 4px;
}
.cooper-confirm .ant-confirm-body {
  margin: 40px;
}
.cooper-confirm .ant-confirm-body::after {
  content: '';
  display: block;
  clear: right;
}
.cooper-confirm .ant-confirm-title,
.cooper-confirm .ant-confirm-content {
  word-break: break-word;
  font-size: 16px;
  display: inline-block;
  width: calc(100% - 40px);
}
.cooper-confirm .ant-confirm-content {
  font-size: 14px;
  float: right;
  margin-left: 40px;
  line-height: 22px;
}
.cooper-confirm .ant-confirm-content > div {
  min-height: 13px;
}
.cooper-confirm .ant-confirm-btns {
  margin: 0 24px 24px;
}
.cooper-confirm .ant-confirm-btns button {
  min-width: 68px;
  padding: 0 16px;
}
.cooper-confirm .ant-confirm-btns button:first-child {
  border-color: #e4e9f3;
}
.cooper-confirm .ant-confirm-btns button.ant-btn-primary {
  border: none;
}
.cooper-confirm .anticon-question-circle {
  padding: 0;
}
.cooper-confirm .anticon-question-circle::before {
  content: '';
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
}
.cooper-confirm .cooper-confirm-title {
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
  display: flex;
}
.cooper-confirm .cooper-confirm-title-icon {
  margin-right: 8px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(./warn.svg);
  background-size: 24px 24px;
}
.cooper-confirm .cooper-confirm-content {
  margin-top: 16px;
  margin-left: 32px;
}
.cooper-confirm .cooper-confirm-content a {
  display: block;
  margin-top: 12px;
  color: #047FFE;
}
.cooper-confirm-no-cancel :global .ant-modal-content .ant-modal-confirm-btns > button:first-child {
  display: none;
}
