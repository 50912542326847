/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.share-trigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #383F49;
  background: #E9EDF0;
  border-radius: 4px;
  min-width: 78px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}
.share-trigger.is-active {
  background: rgba(4, 127, 254, 0.08);
  color: #047FFE;
}
.share-trigger.is-active .status-icon {
  color: #047FFE;
}
.share-trigger .status-icon {
  color: #6A707C;
  font-size: 16px;
  margin-right: 6px;
}
