/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.add {
  display: block;
}
.add .direct-add .operation .dropdown-checkbox.dropdown-smcheckbox {
  float: right;
  width: auto;
}
.add .direct-add .foot button.myantd-btn-primary {
  background: #0B83FF;
}
.add .choose-result-wrap .direct-add-tip {
  display: none;
}
