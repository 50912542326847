/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.perm-option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 8px;
  margin-bottom: 2px;
  border-radius: 4px;
  cursor: pointer;
}
.perm-option-item:hover {
  background-color: #F2F3F3;
}
.perm-option-item .perm-option-label {
  font-size: 14px;
  color: #222A35;
  line-height: 20px;
}
.perm-option-item .perm-option-desc {
  font-size: 12px;
  color: #909499;
  line-height: 17px;
}
.perm-option-item .select-arrow-icon {
  display: inline-block;
  background-image: url('../icon/ico-checked.png');
  width: 12px;
  height: 12px;
  background-size: 12px 12px;
  background-repeat: no-repeat;
}
.perm-option-item .select-arrow-disable__ico {
  background-image: url('../icon/ico-hover.png');
}
.disabled-perm {
  cursor: not-allowed;
}
.disabled-perm .perm-option-label {
  color: #909499;
}
