/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.versioncomparison {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 676px;
  height: 56px;
  width: 100%;
}
.versioncomparison .left {
  height: 100%;
  margin-left: 32px;
}
.versioncomparison .left .backtrack {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
  cursor: pointer;
}
.versioncomparison .left .backtrack .icon {
  font-size: 24px;
  margin-right: 6px;
}
.versioncomparison .left span {
  width: 42px;
  height: 22px;
  border-radius: 3px;
  text-align: center;
  line-height: 22px;
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  display: inline-block;
  margin-right: 6px;
}
.versioncomparison .left .add {
  background: #D4F3E2;
}
.versioncomparison .left .delete {
  text-decoration: line-through;
  background: rgba(255, 195, 203, 0.6);
}
.versioncomparison .left .modify {
  background: #D6E9FF;
}
.versioncomparison .left .verticalBar {
  display: inline;
  height: 24px;
  width: 1px;
  background: #DEE0E3;
  margin: 0px 22px 0px 22px;
  margin-right: 26px;
}
.versioncomparison .left-bock {
  display: flex;
  align-items: center;
}
.versioncomparison .left-none {
  display: none;
}
.versioncomparison .pdemo {
  height: 45px;
}
.versioncomparison .pdemo:hover {
  color: red !important;
  background: none !important;
  background-color: none !important;
}
.versioncomparison .right {
  display: flex;
  align-items: center;
}
.versioncomparison .right .revert-btn {
  width: 116px;
  height: 34px;
  background: #047FFE;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  border: none;
}
.versioncomparison .right .revert-btn:focus,
.versioncomparison .right .revert-btn:hover {
  color: #FFFFFF;
  background: #047FFE;
  border: none;
}
.versioncomparison .right .revert-btn:disabled {
  opacity: 0.5;
}
.versioncomparison .right .revert-btn:global(.ant-btn-loading) {
  width: 138px;
}
.versioncomparison .right .revert-btn::after {
  display: none;
}
.versioncomparison .right .rightVersion {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.versioncomparison .right .rightVersion .comparisonVersion .ant-cascader-picker-disabled .ant-cascader-input {
  color: #252525 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.versioncomparison .right .rightVersion .comparisonVersion .ant-input {
  border: none;
}
.versioncomparison .right .rightVersion .visible-version {
  background: #F7F8F9;
  color: #252525;
  width: 120px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #E2E3E7;
  padding: 5px 12px;
}
.versioncomparison .right .rightVersion .visible-version .visible-version-text {
  font-size: 14px;
  line-height: 22px;
}
.versioncomparison .right .rightVersion .span {
  font-size: 14px;
  font-weight: 400;
  color: #252525;
  margin: 0 10px 0 12px;
}
.versioncomparison .right .rightVersion .customer-btn {
  margin-left: 12px !important;
}
.versioncomparison .right .betaIcon {
  width: 34px;
  height: 18px;
  margin-left: 10px;
}
.versioncomparison .right .versionTipIcon {
  display: flex;
  align-items: center;
}
.versioncomparison .right .versionTipIcon:hover .import-tip {
  color: #047ffe;
}
.versioncomparison .right .import-tip {
  font-size: 14px;
  margin-left: 5px;
  color: #999999;
}
.versioncomparison .right .import-tip:hover {
  color: #047ffe;
}
.moreDk {
  margin: 0 8px 4px 6px;
  transition: all 0.2s;
  color: #666666;
  display: block;
}
.moreDk.isOpen {
  transform: rotate(180deg);
}
.versionComparison {
  width: 120px;
  cursor: pointer;
  height: 34px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E2E3E7;
  justify-content: space-around;
  align-items: center;
  display: flex;
}
.versionComparison:hover {
  border-color: #047ffe;
}
.versionComparison span {
  font-size: 14px;
  margin-left: 12px;
  margin-right: 10px;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
}
.versionComparison i {
  margin: 0px 10px 0 0px;
}
.versionComparison .versionValuable {
  color: #bbbbbb;
}
.versionComparison .versionNovalue {
  color: #252525;
}
.versionComparison .dk-open {
  width: 14px;
  height: 14px;
  margin-left: 26px;
}
.versionComparison .dk-block {
  display: block;
}
.versionComparison .dl-none {
  display: none;
}
:global .version-search-popover {
  padding: 0 !important;
  width: 454px !important;
  max-width: 454px !important;
  background: #FFFFFF;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
:global .version-search-popover .ant-tooltip-inner {
  border-radius: 6px !important;
  box-shadow: none;
  padding: 0 !important;
  border-radius: 0;
}
.comparisonVersion {
  width: 120px;
  height: 34px;
  background: #F7F8F9;
  border-radius: 4px;
  border: 1px solid #E2E3E7;
}
.comparisonVersion :global .ant-cascader-input {
  border: none;
}
.comparisonVersion :global .ant-cascader-picker-label {
  font-size: 14px;
  font-weight: 400;
  color: #252525;
}
.comparisonVersion :global .ant-cascader-picker-arrow {
  display: none;
}
.version-popover-content :global .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-color: #C1C4C8;
}
.version-popover-content :global .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #047FFE;
}
.version-popover-content .versionEmpty {
  text-align: center;
  padding: 4px;
  height: 60px;
  font-size: 12px;
  color: '#727272';
  line-height: 60px;
}
.version-popover-content .content-scroll-box {
  padding-top: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
}
.version-popover-content ul li:hover {
  background: #F4F4F4;
  border-radius: 4px;
  cursor: pointer;
}
.version-popover-content ul {
  max-height: 320px;
  width: 100%;
  padding: 2px 0px 2px 6px;
  overflow-y: scroll;
}
.version-popover-content ul .active {
  background: #EFF5FF;
  border-radius: 4px;
}
.version-popover-content ul .active span {
  color: #047FFE;
}
.version-popover-content ul .active i {
  color: #047FFE;
}
.version-popover-content ul li {
  height: 36px;
  display: flex;
  align-items: center;
}
.version-popover-content ul li span {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
.version-popover-content ul li span:nth-child(1) {
  width: 18%;
  margin-left: 18px;
}
.version-popover-content ul li span:nth-child(2) {
  min-width: 25%;
  margin-left: 36px;
  text-align: left;
}
.version-popover-content ul li span:nth-child(3) {
  width: 20%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 38px;
}
.version-popover-content ul li i {
  margin-left: 32px;
}
.versionTitle {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 100%;
  height: 39px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid #F1F1F1;
}
.versionTitle .version {
  margin-left: 24px;
}
.versionTitle .publictTime {
  margin-left: 86px;
}
.versionTitle .Publisher {
  margin-left: 92px;
}
.versionTitle .endSelect {
  margin-left: 24px;
}
.versionTitle .mineList {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: auto;
}
.content_box::-webkit-scrollbar {
  width: 6px;
  margin-top: 1px;
}
.content_box::-webkit-scrollbar-thumb {
  background: #C1C1C1;
  border-radius: 4px;
  opacity: 0.8;
  margin-top: 1px;
}
.revert-version-modal {
  padding-bottom: 0;
}
.revert-version-modal :global .ant-modal-content {
  border-radius: 6px !important;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body {
  padding: 32px 28px 24px;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  font-size: 24px;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
  margin-top: 36px;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  width: 96px;
  height: 44px;
  background: #F6F6F6;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  color: #444B4F;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn::after {
  display: none;
}
.revert-version-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  margin-left: 16px;
  color: #FFFFFF;
  background: #047FFE;
}
.revert-version-modal .comparisonVersion .ant-cascader-picker-disabled .ant-cascader-input {
  border: none !important;
  color: #252525 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.revert-version-modal .comparisonVersion .ant-input {
  border: none;
}
.revert-version-modal .tip-content {
  font-size: 16px;
  color: #444B4F;
  line-height: 24px;
  margin-top: 10px;
}
.revert-version-modal .tip-content .version {
  color: #444B4F;
  font-weight: 500;
}
.revert-version-modal .tip-content .link {
  color: #047FFE;
}
