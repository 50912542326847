/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.batch-member-btn {
  padding: 4px;
  border-radius: 4px;
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  word-break: break-all;
}
.batch-member-btn:hover {
  background-color: rgba(34, 42, 53, 0.06);
}
.batch-member-btn .dk-icon-wendangyangshishezhi {
  font-size: 14px;
  line-height: 14px;
  margin-right: 4px;
  color: rgba(34, 42, 53, 0.8);
}
.batch-member-btn .batch-text {
  font-size: 12px;
  color: rgba(34, 42, 53, 0.8);
  line-height: 17px;
}
