/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.share-trigger {
  display: inline-flex;
  align-items: center;
  min-width: 102px;
  height: 24px;
  font-size: 12px;
  color: #505050;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  margin-right: 2px;
  cursor: pointer;
  padding: 0 4px;
}
.share-trigger .status-icon {
  color: #999999;
  font-size: 24px;
}
.share-trigger .status-icon.is-active {
  color: #12B944;
}
