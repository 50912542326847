/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.share {
  position: absolute;
  right: 0;
  top: 0;
}
.share-popover .share-content {
  width: 478px;
  padding: 18px 24px 8px 24px;
}
.share-popover .share-content .title {
  font-size: 18px;
  font-weight: 500;
  color: #2F343C;
  line-height: 25px;
}
.share-popover .share-content .switch-global {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-top: 18px;
  line-height: 22px;
}
.share-popover .share-content .share-setting {
  margin-top: 24px;
}
.share-popover .share-content .share-setting .setting-titles {
  font-size: 14px;
  font-weight: 500;
  color: #2F343C;
  line-height: 20px;
  margin-bottom: 10px;
}
.share-popover .share-content .share-setting .setting-content {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.share-popover .share-content .share-setting .setting-content .setting-item {
  margin-bottom: 12px;
}
.share-popover .share-content .share-setting .setting-content .setting-item .disabled-desc {
  margin: 2px 0 0 38px;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
}
.share-popover .share-content .main {
  margin-top: 20px;
  background: #F7F8F9;
  border-radius: 7px;
  color: #444B4F;
  line-height: 30px;
  font-size: 16px;
  padding: 18px 20px;
}
.share-popover .share-close {
  padding-bottom: 20px;
}
.confirm-modal {
  width: 480px;
  margin: auto;
}
.confirm-modal .content {
  font-size: 16px;
  font-weight: 400;
  color: #444B4F;
  line-height: 24px;
}
.confirm-modal :global .ant-modal-content {
  border-radius: 6px;
  overflow: hidden;
}
.confirm-modal :global .ant-modal-content .ant-modal-header {
  padding: 32px 28px 10px;
  border-bottom: none;
}
.confirm-modal :global .ant-modal-content .ant-modal-header .ant-modal-title {
  height: 33px;
  line-height: 33px;
  font-size: 24px;
  font-weight: 500;
  color: #222A35;
}
.confirm-modal :global .ant-modal-content .ant-modal-body {
  padding: 0 28px;
}
.confirm-modal :global .ant-modal-content .ant-modal-footer {
  border-top: none;
  padding: 28px 28px 24px;
}
.confirm-modal :global .ant-modal-content .ant-modal-footer .ant-btn {
  width: 96px;
  height: 44px;
  border-radius: 4px;
  color: #444B4F;
  background: #F6F6F6;
  border: none;
  font-size: 16px;
}
.confirm-modal :global .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary {
  color: #FFFFFF;
  background: #0B83FF;
  margin-left: 16px;
}
.second-floor-modal {
  z-index: 1031 !important;
}
