/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooperFoldAuthm-member-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cooperFoldAuthm-member-list .pic {
  margin-right: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #f2f3f3;
}
.cooperFoldAuthm-member-list .info {
  flex: 1;
}
.cooperFoldAuthm-member-list .info .nameArea {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  height: 14px;
  font-size: 14px;
  color: #111111;
}
.cooperFoldAuthm-member-list .info .nameArea .name {
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
}
.cooperFoldAuthm-member-list .info .nameArea .tag {
  margin-left: 6px;
  padding: 0 2px;
  height: 16px;
  line-height: 16px;
  background: rgba(0, 102, 255, 0.08);
  border-radius: 4px;
  font-size: 12px;
}
.cooperFoldAuthm-member-list .info .nameArea .tag .word {
  display: block;
  color: #0066FF;
  transform-origin: center center;
  transform: scale(0.83333333);
}
.cooperFoldAuthm-member-list .info .nameArea .tag-quit-self {
  background: #f4f4f5;
}
.cooperFoldAuthm-member-list .info .nameArea .tag-quit-self .word {
  color: #666666;
}
.cooperFoldAuthm-member-list .info .nameArea .tag-self {
  padding: 0 5px;
}
.cooperFoldAuthm-member-list .info .nameArea .out-yellow {
  background-color: rgba(241, 139, 0, 0.1);
}
.cooperFoldAuthm-member-list .info .nameArea .out-yellow .word {
  color: #F18B00;
}
.cooperFoldAuthm-member-list .info .mail {
  height: 10px;
  font-size: 12px;
  color: #999999;
  line-height: 10px;
  word-break: keep-all;
}
.cooperFoldAuthm-member-list .powerChoose-content {
  text-align: right;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  padding: 0 26px 0 10px;
  border-radius: 4px;
  font-size: 13px;
  color: #666666;
}
.cooperFoldAuthm-member-list .disabled {
  padding-right: 4px;
  color: #999999;
  background: none;
  cursor: not-allowed;
}
.cooperFoldAuthm-member-list.quit-member {
  opacity: 0.5;
}
