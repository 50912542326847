/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.page-style-trigger {
  cursor: pointer !important;
}
.page-style-trigger::before {
  position: absolute;
  content: '';
  left: -15px;
  top: 0;
  width: 26px;
  height: 59px;
}
.page-style-trigger .page-style-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-style-trigger .page-style-title .icon-expand {
  margin-right: 0;
  margin-top: -2px;
}
.page-style-trigger .page-style-title .icon-page-style {
  vertical-align: text-top;
}
.page-style-trigger:hover .page-style-popover {
  opacity: 1;
  transform: translateX(-100%);
  visibility: visible;
}
.summer-tip {
  font-size: 12px;
  color: #94979B;
  line-height: 18px;
  margin: 2px 0 0 26px;
  text-shadow: 0 6px 36px rgba(34, 45, 60, 0.2);
}
.page-style-popover {
  position: absolute;
  left: -14px;
  top: -11px;
  transform: translateX(-90%);
  padding: 18px 16px 20px;
  background: #FFFFFF;
  box-shadow: 0 6px 36px 0 rgba(34, 45, 60, 0.2);
  border-radius: 6px;
  border: 1px solid #F1F1F1;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
}
.page-style-popover .title {
  font-size: 14px;
  font-weight: 500;
  color: #2B3037;
  line-height: 20px;
  text-shadow: 0 6px 36px rgba(34, 45, 60, 0.2);
}
.page-style-popover .content {
  display: flex;
  margin-top: 10px;
}
.page-style-popover .content .tab {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 118px;
  border-radius: 6px;
  border: 1px solid #F1F1F1;
  cursor: pointer;
  padding: 8px;
  word-break: break-all;
}
.page-style-popover .content .tab:first-child {
  margin-right: 12px;
}
.page-style-popover .content .tab.active {
  border: 1px solid rgba(47, 125, 246, 0.8);
}
.page-style-popover .content .tab.active .small-font,
.page-style-popover .content .tab.active .font-size-16 {
  color: rgba(47, 125, 246, 0.8);
}
.page-style-popover .content .tab.active .narrow-screen-icon {
  background: url("../../assets/icon/narrowScreenActive.png") no-repeat center / contain;
}
.page-style-popover .content .tab.active .wide-screen-icon {
  background: url("../../assets/icon/wideScreenActive.png") no-repeat center / contain;
}
.page-style-popover .content .tab.font-size {
  height: 62px;
}
.page-style-popover .content .tab.page-width {
  height: 106px;
}
.page-style-popover .content .tab .narrow-screen-icon {
  width: 40px;
  height: 28px;
  border-radius: 2px;
  background: url("../../assets/icon/narrowScreen.png") no-repeat center / contain;
}
.page-style-popover .content .tab .wide-screen-icon {
  width: 40px;
  height: 28px;
  border-radius: 2px;
  background: url("../../assets/icon/wideScreen.png") no-repeat center / contain;
}
.page-style-popover .content .tab .text {
  font-size: 13px;
  color: #2B3037;
  line-height: 18px;
  margin-top: 2px;
}
.page-style-popover .content .tab .font-size-14 {
  font-size: 14px;
  line-height: 1;
}
.page-style-popover .content .tab .font-size-16 {
  font-size: 16px;
  line-height: 1;
}
.page-style-popover .content .tab .summary {
  font-size: 12px;
  color: #94979B;
  line-height: 18px;
  margin-top: 2px;
}
.page-style-popover hr {
  height: 1px;
  box-shadow: 0 6px 36px 0 rgba(34, 45, 60, 0.2);
  border: none;
  background: #F4F5F5;
  margin: 15px auto;
}
.page-style-popover .tips {
  display: flex;
  border-top: 1px solid #F4F5F5;
  font-size: 12px;
  line-height: 20px;
  color: #909499;
  padding-top: 12px;
  margin-top: 16px;
}
.page-style-popover .tips .icon {
  margin-right: 2px !important;
}
