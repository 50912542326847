/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-files-list {
  margin: 0 auto;
  min-width: 667px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex: 1;
}
