/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.addGroup .header {
  margin: 8px 30px 14px;
}
.addGroup .header .tip {
  margin-bottom: 20px;
  font-size: 14px;
  color: #666666;
  line-height: 21px;
}
.addGroup .memberList-wrap {
  padding: 0 18px;
}
.addGroup .memberList-wrap .title {
  padding-left: 12px;
  line-height: 36px;
  font-size: 14px;
  font-weight: 500;
  color: #343434;
}
.addGroup .memberList-wrap .title .tipIcon {
  margin: 0 0 2px 2px;
  width: 22px;
  height: 22px;
}
.addGroup .memberList-wrap .memberList {
  height: 361px;
  overflow: auto;
}
.addGroup .memberList-wrap .memberList .avatartIcon {
  margin-right: 10px;
  display: block;
  width: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  color: #FFFFFF;
  background-color: #48C79C;
}
.addGroup .memberList-wrap .memberList .add {
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 14px;
  color: #666666;
}
.addGroup .memberList-wrap .memberList .add:hover {
  color: #343434;
  background: #F4F4F4;
}
.addGroup .memberList-wrap .emptySearch {
  text-align: center;
}
.addGroup .memberList-wrap .emptySearch-icon {
  margin: 80px auto 12px;
  width: 128px;
  height: 128px;
}
.addGroup .memberList-wrap .emptySearch-tip {
  height: 20px;
  font-size: 14px;
  color: #505050;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
}
