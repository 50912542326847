/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.small-btn {
  margin-left: 4px;
}
.small-btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D8DEE3;
  width: 64px;
  height: 24px;
  border-radius: 4px;
  padding: 0;
}
.small-btn button span {
  font-size: 12px;
  font-weight: normal;
  color: #222A35;
  margin-right: 0;
}
.small-btn button:hover {
  background: #EBEEF1;
  border: 1px solid #D8DEE3;
}
.small-btn button:active {
  background: #EBEEF1;
  border: 1px solid #D8DEE3;
}
