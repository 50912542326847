/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.space-detail-wrap {
  height: 100%;
  background-image: url('@/assets/icon/space-bg.png');
  background-position: 0 0;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}
.space-detail-wrap-title {
  padding: 0 24px;
}
.space-detail-loading-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-bot-action {
  display: flex;
}
.header-bot-action button {
  margin-left: 16px;
  border-radius: 3px;
}
.header-bot-action .ant-btn-default {
  border: 1px solid #047FFE;
}
.space-detail-main {
  flex: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}
.space-detail-tabs-trash {
  cursor: pointer;
}
.back-icon {
  font-size: 20px;
  cursor: pointer;
  margin-right: 5px;
}
.knowledge-content {
  padding: 0;
  height: 100%;
}
.dk-has-gap {
  padding: 0 24px;
}
.dk-has-gap :global .utils-drawer {
  right: 24px;
}
.dk-has-gap :global .eidt-tab-intergation-float-container {
  right: 24px;
}
