/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.memberList {
  margin-left: -6px;
  margin-right: -6px;
  overflow-y: auto;
  height: 348px;
}
.memberList .memberItem {
  display: flex;
  align-items: center;
  height: 49px;
  border-radius: 6px;
  padding: 6px 8px;
}
.memberList .memberItem:hover {
  background: #EFF0F2;
}
.memberList .memberItem .check {
  flex-shrink: 0;
  margin-right: 14px;
}
