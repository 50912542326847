/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.search-tag-item {
  position: relative;
  width: 100%;
  padding: 5px 0px 5px 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.search-tag-item:hover {
  background: #F4F4F4;
}
.search-tag-item .item-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  color: #222A35;
}
.search-tag-item .item-used {
  max-width: 70%;
  margin-right: 48px;
  display: inline-block;
}
.search-tag-item .exist-tag {
  border-radius: 2px;
  border: 1px solid rgba(34, 42, 53, 0.15);
  font-weight: 500;
  background-color: #ffffff;
  color: rgba(34, 42, 53, 0.4);
  font-size: 12px;
  line-height: 17px;
  border-radius: 4px;
  padding: 0 3px;
}
