/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.memberList {
  height: 100%;
}
.memberList .memberItem {
  display: flex;
  align-items: center;
  height: 49px;
  border-radius: 6px;
  padding: 6px 8px;
}
.memberList .memberItem:hover {
  background: #F2F3F3;
}
.cooperFoldAuth-removeSelfModal .ant-modal {
  top: calc(50% - 120px);
}
