/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.drag-row {
  background: #FFFFFF;
  box-shadow: 0 4px 16px 0 rgba(47, 52, 60, 0.2);
  border-radius: 4px;
  width: 280px;
  height: 55px;
  display: flex;
  align-items: center;
  position: relative;
}
.drag-row img {
  width: 32px;
  height: 32px;
  margin: 11.8px 19px;
}
.drag-row .drag-span {
  margin-right: 10px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.drag-row .num {
  position: absolute;
  width: 24px;
  height: 24px;
  top: -12px;
  right: -12px;
  font-size: 16px;
  color: #FFFFFF;
  border-radius: 50%;
  background-color: #06f;
  display: flex;
  align-items: center;
  justify-content: center;
}
