/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.batch-button {
  min-width: 300px;
  z-index: 9;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
}
.batch-button :global .ant-btn {
  padding: 0 8px;
}
.batch-button :global .ant-btn:first-child {
  border-radius: 4px 0 0 4px !important;
}
.batch-button :global .ant-btn:last-child {
  border-radius: 0 4px 4px 0 !important;
}
.batch-button :global .ant-btn:only-child {
  border-radius: 4px !important;
}
.batch-button :global .ant-btn-group > .ant-btn:first-child:not(:last-child) {
  padding: 0 8px;
}
.batch-button :global .ant-btn {
  min-width: 60px;
  width: auto;
  height: 32px;
  border: 1px solid #DDDDDD !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.batch-button :global .ant-btn i {
  margin-right: 0 !important;
}
.batch-button :global .ant-btn:hover,
.batch-button :global .ant-btn:focus {
  border-color: #518dff;
  color: #518dff;
  background-color: #fff;
}
