/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.page-member-wrap {
  margin-right: 12px;
}
.page-member-wrap :global .ant-popover-inner {
  overflow: hidden;
  min-height: 630px;
}
.page-member-wrap :global .ant-popover-content {
  width: 640px;
}
.page-member-wrap .member-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  cursor: pointer;
}
.page-member-wrap .member-trigger:hover,
.page-member-wrap .member-trigger.is-active {
  background: #ECEDF0;
}
.page-member-wrap .member-trigger .member-icon {
  font-size: 18px;
  color: #6A707C;
}
.member-popover .member-content-wrap {
  height: 660px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.member-popover .member-content-wrap .flex-top {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 46px);
}
.member-popover .member-content-wrap .member-content-header {
  display: flex;
  justify-content: space-between;
  padding: 26px 26px 24px 31px;
}
.member-popover .member-content-wrap .member-content-header .member-title {
  font-size: 20px;
  font-weight: 500;
  color: #2F343C;
  line-height: 28px;
}
.member-popover .member-content-wrap .member-content-header .member-operate {
  display: flex;
  align-items: center;
}
.member-popover .member-content-wrap .member-content-header .member-operate .inherit-type-wrap {
  margin-right: 12px;
}
.member-popover .member-content-wrap .member-content-header .member-operate .addMemberBtn-inPage {
  width: 28px;
  height: 28px;
  line-height: 26px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
}
.member-popover .member-content-wrap .member-content-header .member-operate .addMemberBtn-inPage:hover {
  background-color: #F1F1F1;
}
.member-popover .member-content-wrap .member-content-header .member-operate .addMemberBtn-inPage-icon {
  font-size: 18px;
  color: #505050;
}
.member-popover .member-content-wrap .member-content-body {
  overflow-y: auto;
  flex: 1;
}
.member-popover .member-content-wrap .member-content-body::-webkit-scrollbar {
  display: none;
}
.member-popover .member-content-wrap .member-content-body .inheritMemberHeader {
  padding-left: 31px;
  height: 54px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  z-index: 1;
}
.member-popover .member-content-wrap .member-content-body .inheritMemberHeader .inheritMember-triangle {
  margin-left: 9px;
  margin-right: 15px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-left: 5px solid rgba(34, 42, 53, 0.7);
  border-bottom: 4px solid transparent;
}
.member-popover .member-content-wrap .member-content-body .inheritMemberHeader .inheritMember-triangle.spread {
  transform: rotate(90deg);
}
.member-popover .member-content-wrap .member-content-body .inheritMemberHeader .inheritMember-title {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
  line-height: 22px;
}
.member-popover .member-content-wrap .member-content-body .inheritMemberHeader .inheritMember-avatar {
  margin: 0 10px 0 0;
  width: 32px;
  height: 32px;
  border-radius: 3px;
}
.member-popover .member-content-wrap .member-content-body .inheritMemberList {
  overflow-y: auto;
  background-color: #F7F8F9;
  box-shadow: inset 2px 2px 5px #eff0f2;
  max-height: 380px;
}
.member-popover .member-content-wrap .member-content-body .individualList {
  overflow-y: auto;
  background-color: #F7F8F9;
  box-shadow: inset 2px 2px 5px #eff0f2;
  max-height: 380px;
}
.member-popover .member-content-wrap .member-content-footer {
  background-color: #ffffff;
}
.addMemberMenu {
  padding: 7px;
}
.addMemberMenu .addMemberMenu-Item {
  padding-left: 13px;
  width: 128px;
  line-height: 36px;
  border-radius: 4px;
  font-size: 14px;
  color: #252525;
  cursor: pointer;
}
.addMemberMenu .addMemberMenu-Item:hover {
  background: #F4F4F4;
}
.addMemberMenu .addMemberMenu-Item i {
  margin-right: 8px;
}
.addGroupModal :global .ant-modal-content {
  min-height: 620px;
}
.addGroupModal :global .ant-modal-content .ant-modal-header {
  padding: 26px 0 0 31px;
}
.addGroupModal :global .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 20px;
}
.addGroupModal :global .ant-modal-content .ant-modal-close-x {
  margin: 22px 40px 0 0;
}
