/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.preview-wrap {
  height: 100%;
}
.preview-wrap .header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E3E5EA;
}
.preview-wrap .header .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
  margin-left: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #222A35;
  line-height: 50px;
}
.preview-wrap .preview {
  padding-right: 25px;
  width: 100%;
  height: 100%;
  border: none;
  background: #F2F4F7;
}
.preview-wrap .preview.hasHeader {
  height: calc(100% - 50px);
}
.preview-wrap .error-desc {
  margin-top: 6px;
  font-size: 16px;
  line-height: 24px;
}
