/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.error-tips {
  top: 38%;
  width: 375px;
}
.share-close-tips {
  font-size: 15px;
  font-weight: 500;
  color: rgba(34, 42, 53, 0.5);
  line-height: 21px;
  text-align: center;
  position: absolute;
  bottom: 89px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  word-break: keep-all;
  min-width: 251px;
}
.share-close-tips .dk-logo {
  margin-left: 2px;
  margin-right: 2px;
  width: 69px;
  height: auto;
}
.share-close-tips .goto-dk-menhu {
  width: 36px;
  height: 36px;
  padding: 7px 6px 8px 8px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(34, 42, 53, 0.5);
  background: #FFFFFF;
  box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #F1F2F3;
  margin-left: 37px;
  border-radius: 50%;
}
.share-close-tips .goto-dk-menhu:hover {
  color: #047FFE;
}
