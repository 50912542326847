/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.v3-capacity {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: #909499;
  font-weight: normal;
  margin-left: 10px;
}
.v3-capacity .used-quota {
  font-size: 12px;
  font-weight: normal;
  margin-right: 0;
}
.v3-capacity .used-quota.red {
  color: #FF563B;
}
.v3-capacity .auth {
  font-size: 12px;
  color: #1A6EFF;
  cursor: pointer;
  margin-left: 12px;
}
