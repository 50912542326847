// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.content-tip {
  cursor: pointer;
  color: rgba(0, 102, 255, 1);
}

.content-title-weight {
  font-weight: bold;
}

.folder-tree {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;

  .checkbox-wrap {
    margin-right: 12px;
  }

  .ft-triangle-wrapper {
    height: 30px;
    width: 19px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tb-body .tb-body-row-fileList:hover {
    background-color: #F3F3F3;
    border-radius: 4px;
  }

  .tb-header>.tb-header-div,
  .tb-body .tb-body-row-fileList {
    height: 46px;
    line-height: 46px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: @blueGray-4;
    padding-left: 4px;

    >span {
      display: inline-block;
    }

    .file-name {
      min-width: 300px;
      max-width: initial;
      width: calc(100% - 448px);
      display: flex;
      align-items: center;
      overflow: hidden;

      .file-name-display {
        display: inline-block;
        flex: 1;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      >.ant-checkbox-wrapper {
        position: relative;
        top: -2px;
        margin-right: 18px;
      }
    }

    .file-resizer {
      width: 20px;
      margin-right: 8px;
      margin-left: 12px;
      position: relative;
      text-align: center;

      .resizer-line {
        width: 1px;
        height: 50px;
        background: #076BFF;
        position: absolute;
        top: 0;
        left: 50%;
      }
    }

    >.file-time {
      width: 130px;
      margin-right: 30px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-owner {
      width: 130px;
      margin-right: 30px;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    >.file-owner+.file-time {
      margin-right: 4px;
    }

    >.file-operate {
      width: 114px;
      padding-right: 4px;
      display: flex;
      justify-content: right;
      align-items: center;
    }

    .triangle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 19px;
      margin-right: 10px;
      font-size: 16px;
      // background-size: 8px 8px;
      // background-image: url(https://img-ys011.didistatic.com/static/cooper_cn/do1_Bw1673hz9vMmk8p48HJH);
      // background-position: center center;
      // background-repeat: no-repeat;
      cursor: pointer;
      color: @blueGray-4;

      &-li {
        margin-left: 4px;
      }
      
    }
  }
  .is-open {
    font-size: 16px;
    transform: rotate(90deg);
  }
  .is-loading {
    font-size: 14px !important;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
   0%{
    transform: rotate(0);
   }
   50% {
    transform: rotate(180deg);
   }
   100% {
    transform: rotate(360deg);
   }
  }
  .tb-header {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    width: max-content;
    min-width: 100%;
    padding: 0 24px;
    display: flex;

    .tb-header-div {
      width: max-content;
      min-width: 100%;
      display: flex;
      height: 36px !important;
      line-height: 36px !important;
      border-bottom: 1px solid @blueGray-11;

      .file-operate,
      .file-owner,
      .file-time,
      .file-name {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 22px;

        .ant-table-column-sorter {

          .anticon-caret-up,
          .anticon-caret-down {
            font-weight: bold;
          }
        }
      }
    }
  }

  .tb-header,
  .tb-body {

    .tb-body-row-fileList.bottom-tip {
      pointer-events: none;
      border: none;
      color: @blueGray-4;
      display: flex;
      font-size: 14px;
      justify-content: center;
      margin-bottom: 32px;
    }
  }

  .tb-body {
    height: calc(100% - 40px);
    min-width: 100%;
    width: max-content;
    overflow: hidden;
    padding: 8px 24px 0;
    &:hover{
      overflow: auto;
    }

    .file-name {
      color: @blueGray-1;
    }
  }

  .tb-body-row-fileList {
    padding: 0 4px;

    &:hover {
      border-radius: 4px;
      background: @blueGray-12;
    }
  }

  :global {
    .ant-btn {
      min-width: 60px;
      width: auto;
      height: 32px;
      border: 1px solid #DDDDDD !important;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;

      &:hover,
      &:focus {
        border-color: #518dff;
        color: #518dff;
        background-color: #fff;
      }
    }
  }

  .loading-tip {
    margin-top: 14px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    .text {
      margin-left: 4px;
    }
  }

}

.person-list {

  .tb-header>.tb-header-div,
  .tb-body .tb-body-row-fileList {
    .file-name {
      width: calc(100% - 280px);
    }

    >.file-operate {
      width: 80px;
      padding-right: 4px;
      display: flex;
      justify-content: right;
      align-items: center;
    }
  }
}
