/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.bread-crumb .bread-crumb-title .breadcrumb-item-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  color: #656A72;
  font-size: 14px;
  margin: 0 4px;
  vertical-align: middle;
}
.bread-crumb .bread-crumb-title .breadcrumb-item-text.is-last {
  font-weight: 500;
  color: #656A72;
}
.bread-crumb .bread-crumb-title .breadcrumb-item-text:hover {
  font-weight: 500;
  color: #656A72;
}
.bread-crumb .bread-crumb-title .breadcrumb-item-text .bread-omit {
  cursor: pointer;
  font-size: 14px;
  vertical-align: text-top;
}
.bread-crumb-container :global .ant-popover-inner-content {
  width: 360px;
  max-height: 452px;
  padding: 7px !important;
  margin: 0;
  overflow-y: auto;
}
.bread-crumb-container .pop-item-link {
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  padding: 0 12px;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bread-crumb-container .pop-item-link .pop-name {
  color: #333333;
  font-size: 14px;
}
.bread-crumb-container .pop-item-link:hover {
  background: #F3F3F3;
}
.bread-crumb-container .pop-item-link:hover a {
  color: #222A35;
}
