/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.knowledge_scrollTableList_wrap :global .ant-table.ant-table-middle .ant-table-thead > tr > th {
  padding: 0 0 10px 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(34, 42, 53, 0.08);
}
.knowledge_scrollTableList_wrap :global .ant-table.ant-table-middle .ant-table-tbody > tr > td {
  padding: 16px 0 17px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.knowledge_scrollTableList_wrap :global .ant-table.ant-table-middle .ant-table-tbody > tr > td:first-child {
  padding: 0 0 0 0;
}
.knowledge_scrollTableList_wrap :global .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #F4F4F4;
}
.knowledge_scrollTableList_wrap :global .ant-table.ant-table-middle .ant-table-body::-webkit-scrollbar {
  display: none;
}
.knowledge_scrollTableList_wrap :global .ant-table-thead {
  height: 21px;
  font-size: 15px;
  font-weight: 500;
  color: #494F55;
  line-height: 21px;
}
.knowledge_scrollTableList_wrap :global .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}
.knowledge_scrollTableList_wrap :global .ant-table-placeholder {
  display: none;
}
.knowledge_scrollTableList_wrap :global .ant-table-cell {
  height: 20px;
  font-size: 14px;
  color: #505050;
  line-height: 20px;
}
.dc-knowledge_scrollTableList_wrap {
  margin-right: 28px !important;
}
