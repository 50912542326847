/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.batch-modal {
  height: 630px !important;
  min-height: 630px !important;
}
.batch-modal .ant-modal-content {
  width: 800px;
  min-width: 800px;
  border-radius: 8px;
  overflow: hidden;
}
.batch-modal .ant-modal-content .ant-modal-header {
  padding: 20px 24px 12px !important;
  border-bottom: none !important;
}
.batch-modal .ant-modal-body {
  padding: 0 !important;
}
.batch-modal .ant-modal-body .batch-modal-content {
  border: 1px solid #EEEEEF;
  margin: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  height: 494px;
}
.batch-modal .ant-modal-body .batch-modal-content-left {
  width: 432px;
  min-width: 432px;
  border-right: 1px solid #eee;
  padding: 12px 0;
  height: 494px;
}
.batch-modal .ant-modal-body .batch-modal-content-left-input {
  padding: 0 14px;
  margin-bottom: 10px;
}
.batch-modal .ant-modal-body .batch-modal-content-left-input input {
  height: 24px;
  width: 100%;
  background: url('../icon/search.png') 0px 5px no-repeat;
  background-size: 16px 16px;
  padding-left: 20px;
}
.batch-modal .ant-modal-body .batch-modal-content-left-input input::placeholder {
  color: #BDC0C3;
}
.batch-modal .ant-modal-body .batch-modal-content-left .list-wrap-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 0 10px 0 16px;
  margin-bottom: 4px;
}
.batch-modal .ant-modal-body .batch-modal-content-left .list-wrap-title-left {
  display: flex;
  align-items: center;
}
.batch-modal .ant-modal-body .batch-modal-content-left .list-wrap-title-left-checkbox .ant-checkbox .ant-checkbox-inner {
  border-radius: 3px !important;
}
.batch-modal .ant-modal-body .batch-modal-content-left .list-wrap-title-left-checkbox span:last-child {
  padding: 0px;
}
.batch-modal .ant-modal-body .batch-modal-content-left .list-wrap-title-left-text {
  font-family: PingFang SC;
  color: #222A35;
  font-weight: 400;
  font-size: 14px;
  padding: 0 8px;
}
.batch-modal .ant-modal-body .batch-modal-content-left .list-wrap-title-left-tips {
  font-size: 12px;
  color: #909499;
}
.batch-modal .ant-modal-body .batch-modal-content-left .list-wrap-title-right-content {
  cursor: pointer;
  height: 20px;
  padding: 0 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #656A72;
}
.batch-modal .ant-modal-body .batch-modal-content-left .list-wrap-title-right:hover {
  background-color: #F2F3F3;
  color: #656A72;
  border-radius: 4px;
}
.batch-modal .ant-modal-body .batch-modal-content-left .list-wrap {
  height: 406px;
  min-height: 406px;
  overflow: auto;
  position: relative;
}
.batch-modal .ant-modal-body .batch-modal-content-left .search-list {
  height: 426px;
  min-height: 426px;
  overflow: auto;
  position: relative;
}
.batch-modal .ant-modal-body .batch-modal-content-right {
  width: 318px !important;
  min-width: 318px !important;
  padding: 12px 8px;
  height: 494px;
}
.batch-modal .ant-modal-body .batch-modal-content-right .list-wrap-title {
  height: 16px;
  line-height: 14px;
  font-size: 12px;
  color: #999;
  padding: 0 8px;
}
.batch-modal .ant-modal-body .batch-modal-content-right .list-wrap {
  height: 448px;
  min-height: 448px;
  overflow: auto;
  position: relative;
}
.batch-modal .ant-modal-body .batch-modal-footer {
  padding: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.batch-modal .ant-modal-body .batch-modal-footer-left {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #222A35;
  font-size: 14px;
}
.batch-modal .ant-modal-body .batch-modal-footer-left .dropdown-checkbox {
  width: 260px;
}
.batch-modal .ant-modal-body .batch-modal-footer-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.batch-modal .ant-modal-body .batch-modal-footer-right-btn {
  margin-left: 12px;
}
.batch-modal .ant-modal-body .batch-modal-footer-right-btn:first-child:hover {
  background-color: #F2F3F3;
  border-color: rgba(34, 42, 53, 0.08);
  color: #222A35;
}
.list-wrap-title-popover {
  padding-bottom: 0px !important;
}
.list-wrap-title-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 16px !important;
  border: 1px solid #f2f3f3;
  border-radius: 6px !important;
}
.popoverContent {
  font-size: 14px;
  color: #222A35;
}
.popoverContent .link {
  font-weight: 500;
  cursor: pointer;
  color: #047FFE;
}
.popoverContent .dk-iconfont {
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
}
.list-wrap .ant-spin {
  padding-top: 200px;
  width: 386px;
}
.remove-member {
  color: #FF563B;
}
.ant-message-warning {
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-popover-content {
  width: 360px;
  padding: 10px;
}
.remove-popover-content-title {
  margin-bottom: 16px;
  color: #222a35;
  display: flex;
}
.remove-popover-content-title .dk-icon-a-tishi2 {
  color: #ffa50c;
  margin-right: 8px;
  margin-top: 2px;
}
.remove-popover-content-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.remove-popover-content-footer button {
  margin-left: 10px;
}
