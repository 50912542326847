/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.identity-select {
  cursor: pointer;
  font-size: 13px;
  color: #505050;
  padding: 3px 0 3px 7px;
  border-radius: 4px;
}
.identity-select:hover {
  background: rgba(0, 0, 0, 0.05);
}
.identity-select .identity-text {
  display: inline-block;
  text-align: right;
  margin-right: 8px;
}
.identity-select .identity-text .identity-text-large {
  font-size: 14px;
  color: #505050;
  line-height: 18px;
}
.identity-select .identity-text .identity-text-little {
  font-size: 12px;
  color: #999999;
  line-height: 17px;
  margin-top: 1px;
}
.identity-select .openChangePower {
  padding-right: 7px;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
}
.identity-select .openChangePower.hideChangePower {
  display: none;
}
.noHover {
  cursor: default;
}
.noHover:hover {
  background: none;
}
:global .identity-select-popover.ant-popover {
  width: 298px !important;
}
:global .identity-select-popover.ant-popover-inner {
  min-height: 10px !important;
}
.changePower-inherit {
  padding: 7px;
  border-radius: 6px;
}
.changePower-inherit .change-perm-text-wrap {
  padding: 7px 15px 14px 15px;
  font-size: 12px;
  color: #777777;
  line-height: 17px;
  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 7px;
}
.changePower-inherit .resume {
  background: rgba(4, 127, 254, 0.06);
  border-radius: 6px;
  padding: 6px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.changePower-inherit .resume .resume-text {
  font-size: 12px;
  font-weight: 400;
  color: #222A35;
  line-height: 18px;
}
.changePower-inherit .resume .resume-btn {
  font-size: 12px;
  font-weight: 400;
  color: #047FFE;
  line-height: 18px;
  border: none;
  background: none;
  box-shadow: none;
  height: auto;
  word-spacing: -2px;
}
.changePower-inherit .changePower-item {
  cursor: pointer;
  border-radius: 6px;
}
.changePower-inherit .changePower-item .item-context-wrap {
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
}
.changePower-inherit .changePower-item .item-context-wrap:hover:not(.disabled) {
  background: #F5F5F6;
}
.changePower-inherit .changePower-item .item-context-wrap.disabled {
  color: #999;
}
.changePower-inherit .changePower-item .item-context-wrap .item-context {
  font-size: 14px;
  color: #222A35;
  line-height: 20px;
}
.changePower-inherit .changePower-item .item-context-wrap .item-context .item-context-text {
  font-size: 12px;
  color: #777777;
  line-height: 17px;
  margin-top: 3px;
}
.changePower-inherit .changePower-item .item-context-wrap .checkIcon {
  margin-right: 11px;
  width: 12px;
  height: 12px;
}
.changePower-inherit .changePower-item .checkIcon {
  margin-right: 0 !important;
}
.changePower-inherit .remove-special {
  border-top: 1px solid #F0F0F0;
  padding: 7px 0;
  margin-top: 7px;
}
.changePower-inherit .remove-special .item-context-title {
  color: #FE0B19;
}
.changePower-inherit .bottom-link {
  border-top: 1px solid #F0F0F0;
  padding-top: 7px;
  margin-top: 7px;
  border-radius: 6px;
}
.changePower-inherit .bottom-link .bottom-link-context {
  padding: 8px 15px;
  font-size: 14px;
  color: #222A35;
  line-height: 20px;
  display: block;
  border-radius: 6px;
}
.changePower-inherit .bottom-link .bottom-link-context:hover {
  background: #F5F5F6;
}
.changePower-inherit .bottom-link .bottom-link-context .perm-link {
  margin-left: 6px;
  font-size: 12px;
  color: #999999;
}
.hideChangePowerPopover {
  display: none;
}
.hide {
  visibility: hidden;
}
