/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.custom-modal-content {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.custom-modal-content .custom-confirm-icon {
  width: 132px;
  height: 132px;
  margin-right: 24px;
  margin-left: 8px;
}
.custom-modal-content .custom-text {
  flex: 1;
  position: relative;
  top: 8px;
}
.custom-modal-content .custom-text .text-h1 {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #222A35;
}
.custom-modal-content .custom-text .text-p {
  font-size: 16px;
  line-height: 24px;
  color: #444B4F;
}
:global .custom-confirm-container-viewDetails .ant-btn:first-child {
  color: #444B4F;
  background: #F6F6F6;
}
:global .custom-confirm-container .ant-modal-content .ant-modal-confirm-btns {
  margin-top: 34px;
}
:global .custom-confirm-container .ant-btn {
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
  border: none;
  height: auto;
  padding: 8px 28px;
}
:global .custom-confirm-container .ant-btn:first-child {
  color: #444B4F;
  background: #F6F6F6;
  margin-right: 8px;
}
:global .custom-confirm-container .ant-modal-confirm-btns .ant-btn:last-child {
  color: #FFFFFF;
  font-weight: 500;
}
