/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.resource-member-wrap .list-header-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.resource-member-wrap .list-header-input .search-input {
  width: 100%;
  height: 36px;
  line-height: 36px;
  border: 1px solid #D3D4D7;
  background: url('../../icon/search.png') 12px center no-repeat;
  background-size: 18px 18px;
  padding-left: 32px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.resource-member-wrap .list-header-input .add-member-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
.resource-member-wrap .list-header-input .add-member-content .split-line {
  margin-right: 12px;
  height: 30px;
  width: 1px;
  background-color: #F2F3F3;
}
.resource-member-wrap .list-wrap .list-wrap-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}
.resource-member-wrap .list-wrap .list-wrap-header .list-header-title {
  font-size: 14px;
  font-weight: 500;
  color: #222A35;
  line-height: 22px;
}
.resource-member-wrap .list-wrap .list-wrap-content {
  position: relative;
  max-height: 392px;
  min-height: 200px;
  overflow: auto;
  margin-left: -8px;
  margin-right: -8px;
}
.resource-member-wrap .list-wrap .list-wrap-content .no-search-result {
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  line-height: 200px;
  text-align: center;
}
