/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.editor {
  position: relative;
  z-index: 0;
  height: 100%;
  overflow: auto;
}
.editor .editor-container {
  height: 100%;
}
.editor .editor-container :global .didoc-editor-app .editor-menu-wrapper {
  display: none;
}
.editor .editor-container :global .didoc-editor-app .editor .editor-title {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333 !important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
  padding: 18px 40px!important;
  margin-top: 22px !important;
}
.editor .editor-container :global .didoc-editor-app .editor .editor-title .delete {
  background-color: rgba(255, 195, 203, 0.6);
  text-decoration: line-through;
}
.editor .editor-container :global .didoc-editor-app .editor .editor-title .delete:hover {
  cursor: pointer;
  background-color: rgba(255, 195, 203, 0.8);
}
.editor .editor-container :global .didoc-editor-app .editor .editor-title .add {
  background-color: #D4F3E2;
}
.editor .editor-container :global .didoc-editor-app .editor .editor-title .add:hover {
  cursor: pointer;
  background-color: #BDE6D5;
}
.editor .editor-container :global .didoc-editor-app .editor .didoc-editor-container {
  padding: 0 40px 15px!important;
}
.editor .editor-container :global .didoc-editor-app .editor .loading {
  display: none;
}
