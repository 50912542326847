/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.scope-name {
  width: calc(100% - 12px);
  color: #222A35;
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.scope-name .scope-text {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.scope-name i {
  cursor: pointer;
  font-size: 16px;
  margin-left: 4px;
}
.switch-scope-popover {
  width: 360px;
}
.safe-msg-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.safe-msg-content {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 6px;
}
:global .outer-tips .ant-popover-inner {
  max-width: 400px;
  text-align: justify;
  word-wrap: break-word;
  white-space: pre-wrap;
}
:global .outer-tips .ant-popover-inner .ant-popover-inner-content {
  padding: 16px;
}
.operate-popover {
  cursor: pointer;
}
.operate-popover :global .tag-global {
  margin-left: 4px;
}
