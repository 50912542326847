// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.versioncomparison {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 676px;
    height: 56px;
    width: 100%;
    .left {
      height: 100%;
      margin-left: 32px;
      .backtrack {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: @text-color;
        cursor: pointer;
        .icon {
          font-size: 24px;
          margin-right: 6px;
        }
      }
      span{
        width: 42px;
        height: 22px;
        border-radius: 3px;
        text-align: center;
        line-height: 22px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        display: inline-block;
        margin-right: 6px;
      }
      .add{
        background: #D4F3E2;
      }
      .delete{
        text-decoration:line-through;
        background:rgba(255,195,203,0.6);
      }
      .modify{
        background: #D6E9FF;
      }
      .verticalBar{
        display: inline;
        height: 24px;
        width: 1px;
        background: #DEE0E3;
        margin:0px 22px 0px 22px;
        margin-right: 26px;
  
      }
  
    }
    .left-bock{
      display: flex;
      align-items: center;
    }
    .left-none{
      display: none;
    }
    .pdemo{
      height: 45px;
      &:hover{
        color:red !important;
        background: none !important;
        background-color: none !important;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .revert-btn {
        width: 116px;
        height: 34px;
        background: @primary-color;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        border: none;
  
        &:focus, &:hover {
          color: #FFFFFF;
          background: @primary-color;
          border: none;
        }
  
        &:disabled {
          opacity: 0.5;
        }
  
        &:global(.ant-btn-loading) {
          width: 138px;
        }
  
        &::after {
          display: none;
        }
      }
      .rightVersion{
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .comparisonVersion{
          .ant-cascader-picker-disabled .ant-cascader-input{
            color: #252525 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
          }
          .ant-input{
            border:none;
          }
        }
        .visible-version{
          background: #F7F8F9;
          color: #252525;
          width: 120px;
          height: 34px;
          border-radius: 4px;
          border: 1px solid #E2E3E7;
          padding: 5px 12px;
          .visible-version-text{
            font-size: 14px;
            line-height: 22px;
          }
        }
        .span{
          font-size: 14px;
          font-weight: 400;
          color: #252525;
          margin:0 10px 0 12px;
        }
        .customer-btn{
          margin-left: 12px !important;
        }
      }
      .betaIcon{
        width: 34px;
        height: 18px;
        margin-left: 10px;
      }
      .versionTipIcon{
        display: flex;
        align-items: center;
        &:hover{
          .import-tip{
            color: rgba(4, 127, 254, 1);
          }
        }
      }
      .import-tip{
        font-size: 14px;
        margin-left: 5px;
        color: #999999;
        &:hover{
          color: rgba(4, 127, 254, 1);
        }
      } 
    }  
  }
  .moreDk {
    margin: 0 8px 4px 6px;
    transition: all 0.2s;
    color: #666666;
    display: block;
    &.isOpen {
      transform: rotate(180deg);
    }
  }
  .versionComparison{
    width: 120px;
    cursor: pointer;
    height: 34px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E2E3E7;
    justify-content: space-around;
    align-items: center;
    display: flex;
    &:hover{
      border-color:#047ffe;
    }
    span{
      font-size: 14px;
      margin-left: 12px;
      margin-right:10px;
      font-weight: 400;
      height: 22px;
      line-height: 22px;
    }
    i {
      margin:0px 10px 0 0px;
    }
    .versionValuable{
      color: #bbbbbb;
    }
    .versionNovalue{
      color: #252525;
    }
    .dk-open{
      width: 14px;
      height: 14px;
      margin-left: 26px;
    }
    .dk-block{
      display: block;
    }
    .dl-none{
      display: none;
    }
  }
  :global{
    .version-search-popover{
      padding: 0 !important;
      width: 454px !important;
      max-width: 454px !important;
      background: #FFFFFF;
      box-shadow: 0px 6px 30px 0px rgba(0,0,0,0.12);
      border-radius: 6px;
      .ant-tooltip-inner{
        border-radius: 6px !important;
        box-shadow: none;
        padding: 0 !important;
        border-radius: 0;
      }
    }
  }
  .comparisonVersion{
    width: 120px;
    height: 34px;
    background: #F7F8F9;
    border-radius: 4px;
    border: 1px solid #E2E3E7;
    :global{
      .ant-cascader-input{
        border:none;
      }
      .ant-cascader-picker-label{
        font-size: 14px;
        font-weight: 400;
        color: #252525;
      }
      .ant-cascader-picker-arrow{
        display: none;
      }
    }
  }
  
  .version-popover-content{
    :global{
      .ant-checkbox .ant-checkbox-inner{
        width: 16px;
        height: 16px;
        border-color: #C1C4C8;
      }
      .ant-checkbox:hover .ant-checkbox-inner{
        border-color: #047FFE;
      }
    }
    .versionEmpty{
      text-align: center;
      padding: 4px;
      height: 60px;
      font-size: 12px;
      color: '#727272';
      line-height: 60px;
    } 
    .content-scroll-box{
      padding-top:2px;
      padding-right: 2px;
      padding-bottom: 2px;
    }
    ul li:hover{
      background: #F4F4F4;
      border-radius: 4px;
      cursor: pointer;
    }
    ul{
      max-height: 320px;
      width: 100%;
      padding: 2px 0px 2px 6px;
      overflow-y: scroll;
      .active{
        background:#EFF5FF;
        border-radius: 4px;
        span{
          color:#047FFE;
        }
        i{
          color: #047FFE;
        }
      }
      li{
        height: 36px;
        display: flex;
        align-items: center;
        span{
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
        }
        span:nth-child(1){
          width: 18%;
          margin-left: 18px;
          
        }
        span:nth-child(2){
          min-width: 25%;
          margin-left: 36px;
          text-align: left;
        }
        span:nth-child(3){
          width: 20%;
          margin-left: 38px;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-left: 38px;
        }
        i{
          margin-left: 32px;
        }
      }
    }
  }
  .versionTitle{
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    width: 100%;
    height: 39px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    border-bottom: 1px solid #F1F1F1;
    .version{
      margin-left: 24px;
    }
    .publictTime{
      margin-left: 86px;
    }
    .Publisher{
      margin-left: 92px;
    }
    .endSelect{
      margin-left: 24px;
    }
   
    .mineList{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      cursor: auto;
    }
  }
  
  .content_box::-webkit-scrollbar {
    width: 6px;
    margin-top:1px;
  }
  .content_box::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 4px;
    opacity: 0.8;
    margin-top:1px;
  }
  .revert-version-modal {
    padding-bottom: 0;
    :global {
      .ant-modal-content {
        border-radius: 6px !important;
        .ant-modal-body {
          padding: 32px 28px 24px;
          .ant-modal-confirm-title {
            font-size: 24px;
          }
          .ant-modal-confirm-btns {
            margin-top: 36px;
            .ant-btn {
              width: 96px;
              height: 44px;
              background: #F6F6F6;
              border-radius: 4px;
              border: none;
              font-size: 16px;
              color: #444B4F;
  
              &::after {
                display: none;
              }
  
              &.ant-btn-primary {
                margin-left: 16px;
                color: #FFFFFF;
                background: @primary-color;
              }
            }
          }
        }
      }
      
  
    }
  
    .comparisonVersion{
      .ant-cascader-picker-disabled .ant-cascader-input{
        border:none !important;
        color: #252525 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
      }
      .ant-input{
        border:none;
      }
    }
  
    .tip-content {
      font-size: 16px;
      color: #444B4F;
      line-height: 24px;
      margin-top: 10px;
  
      .version {
        color: #444B4F;
        font-weight: 500;
      }
  
      .link {
        color: @primary-color;
      }
    }
  }
  