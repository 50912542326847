/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.team-quota-container .quota-btn {
  display: none;
}
.team-quota-container-hover:hover .quota-detail {
  display: none;
}
.team-quota-container-hover:hover .quota-btn {
  display: block;
}
.quota-btn span {
  font-size: 12px;
}
.quota-detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.quota-detail .quota-img {
  width: 16px;
  height: 16px;
}
.quota-detail .quota-word {
  font-size: 12px;
  color: #94a0b0;
  margin-left: 6px;
}
.quota-detail .red-word {
  color: #ff3f5a;
}
.quota-detail-tooltip-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.quota-detail-tooltip-inner .tooltip-inner-front {
  font-size: 12px;
  color: #fff;
}
.quota-detail-tooltip-inner .tooltip-inner-end {
  font-size: 12px;
  color: #0066ff;
  cursor: pointer;
}
.space-quota-modal .team-quota-hint {
  font-size: 14px;
  color: #8a93a8;
}
.space-quota-modal .team-quota-hint .team-quota-hint-num {
  color: #222A35;
  font-weight: bolder;
  margin-left: 2px;
}
.space-quota-modal .ant-confirm-btns button {
  padding: 0;
}
.space-quota-modal .icon-local-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}
.space-quota-modal .ant-modal-confirm-content {
  margin-left: 0!important;
  margin-top: 16px!important;
}
.space-quota-modal .modal-close-btn {
  top: 36px;
  right: 34px;
}
.space-quota-modal .ant-btn-primary {
  padding: 0!important;
}
