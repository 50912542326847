/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.memberInput {
  outline: none;
  padding: 8px 42px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #DDDDDD;
  background: none 16px center no-repeat;
  background-size: 18px 18px;
  width: 580px;
  font-size: 14px;
}
.memberInput.inSetup {
  width: 420px;
  margin-left: 0;
}
.memberInput:focus {
  outline: none;
  border: 0.5px solid #088FFC;
  box-shadow: 0 0 0 1.5px rgba(8, 143, 252, 0.1);
}
.memberInput:focus::-webkit-input-placeholder {
  visibility: hidden;
}
.memberInput.error {
  border: 0.5px solid rgba(255, 86, 59, 0.5);
  box-shadow: 0 0 0 1px rgba(255, 86, 59, 0.3);
}
.memberInput::placeholder {
  color: #999999;
}
