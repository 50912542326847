/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.add-member-btn {
  padding: 7px 11px;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(34, 42, 53, 0.2);
}
.add-member-btn:hover {
  background-color: rgba(34, 42, 53, 0.06);
}
.add-member-btn .add-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.add-member-btn .add-text {
  font-size: 14px;
  font-weight: 500;
  color: #222A35;
  line-height: 20px;
  word-break: keep-all;
}
