/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.pagetag-wrap {
  display: flex;
  align-items: center;
  padding: 10px 24px;
}
.pagetag-wrap .icon-tag {
  font-size: 14px;
  color: #AEAFB2;
}
.pagetag-wrap .icon-add-tag {
  margin-left: 12px;
  font-size: 12px;
  color: #505050;
  border: 1px solid #E5E8EB;
  background: #F8F8F8;
  border-radius: 13px;
  padding: 3px 8px;
  cursor: pointer;
  min-width: 54px;
  word-break: keep-all;
  display: flex;
}
.pagetag-wrap .icon-add-tag:before {
  margin-right: 2px;
}
.pagetag-wrap .icon-add-tag:hover {
  background: #047FFE;
  border: 1px solid #047FFE;
  color: #FFFFFF;
}
.pagetag-wrap .icon-add-tag-active {
  background: #047FFE;
  border: 1px solid #047FFE;
  color: #FFFFFF;
}
.pagetag-wrap .tags-list {
  margin-left: 12px;
  color: #8C8C8C;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pagetag-wrap .tags-list .tag-item {
  max-width: 256px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.pagetag-wrap .tags-list .tag-item:hover {
  color: #047FFE;
}
.pagetag-wrap .tags-list .safety-level-low {
  color: #EE9F00;
}
.pagetag-wrap .tags-list .safety-level-low:hover {
  color: #EE9F00;
}
.pagetag-wrap .tags-list .safety-level-high {
  color: #FF563B;
}
.pagetag-wrap .tags-list .safety-level-high:hover {
  color: #FF563B;
}
.pagetag-wrap .tags-list .safety-level-icon {
  font-size: 13px;
  margin-right: 4px;
}
.pagetag-wrap .ellipsis-sign {
  color: #8C8C8C;
  margin-left: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}
.pagetag-wrap .tags-manager-btn {
  padding: 2px 10px;
  background: #FFFFFF;
  border: 1px solid #E5E8EB;
  border-radius: 12px;
  margin-left: 8px;
  color: #8C8C8C;
  font-size: 12px;
  font-weight: 500;
  margin-right: 20px;
  height: 22px;
  word-break: keep-all;
  line-height: 17px;
  min-width: 70px;
  cursor: pointer;
  min-width: 90px;
}
.pagetag-wrap .tags-manager-btn:hover {
  background: #EEEEEE;
  border: 1px solid #EEEEEE;
}
.pagetag-wrap .tags-manager-btn-active {
  background: #EEEEEE;
  border: 1px solid #EEEEEE;
}
