/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.icon-switch {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 16px;
  color: rgba(34, 42, 53, 0.7);
  cursor: pointer;
}
.icon-switch:hover {
  background-color: #EBEEF1;
}
.popover-content .popover-content-li {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.popover-content .popover-content-li .icon-selected {
  display: none;
  color: #076BFF;
}
.popover-content .popover-content-li-selected .icon-selected {
  display: inline-block;
  color: #076BFF;
}
