/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.tags-manage-container {
  width: 428px;
  padding: 20px 0 24px 20px;
}
.tags-manage-container .title {
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
  line-height: 22px;
  margin-bottom: 16px;
}
.tags-manage-container .title .tag-help {
  margin-left: 6px;
  color: #666666;
  background: #FFFFFF;
  font-size: 14px;
  padding: 4px;
  border-radius: 2px;
  cursor: pointer;
}
.tags-manage-container .title .tag-help:hover {
  background: #F0F1F2;
}
.tags-manage-container .content {
  max-height: 436px;
  overflow: auto;
}
.tags-manage-container .content .content-name-btn {
  text-align: left;
  padding: 6px 12px;
  background: #F0F0F0;
  border: #F0F0F0;
  border-radius: 16px;
  color: #111111;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-right: 8px;
  margin-bottom: 10px;
  cursor: pointer;
}
.tags-manage-container .content .content-name-btn:hover {
  background: #047FFE;
  border: #047FFE;
  color: #FFFFFF;
  font-weight: 500;
}
.tags-manage-container .content .content-name-btn:hover .tag-del {
  color: rgba(255, 255, 255, 0.7);
}
.tags-manage-container .content .content-name-btn .tag-del {
  font-size: 16px;
  color: #979797;
  margin-left: 12px;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.tags-manage-container .content .content-name-btn .tag-del:hover {
  color: #ffffff;
}
.tags-manage-container .content .safety-level .safety-level-icon {
  font-size: 13px;
  margin-right: 6px;
}
