/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 676px;
  height: 56px;
  padding: 0 40px 0 24px;
  border-bottom: 1px solid #F4F5F5;
  margin-left: 296px;
}
