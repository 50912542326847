/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.choosePermResult-wrap {
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 12px 7px 8px;
}
.choosePermResult-wrap:hover {
  background: #F4F4F4;
}
.choosePermResult {
  font-size: 14px;
  color: #505050;
}
.choosePermIcon {
  margin-left: 7px;
  width: 16px;
  height: 16px;
}
.choosePermPopover {
  z-index: 1041;
}
.choosePermPopover :global .ant-popover-content .ant-popover-inner-content {
  padding: 7px;
}
.choosePermPopover .choosePermChoice {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 11px 0 13px;
  width: 128px;
  line-height: 36px;
  border-radius: 4px;
  font-size: 14px;
  color: #252525;
}
.choosePermPopover .choosePermChoice:hover {
  background: #F4F4F4;
}
.choosePermPopover .choosePermChoice .choosePermChoice-icon {
  color: #0066FF;
  font-size: 12px;
}
.choosePermPopover .choosePermChoice.del {
  color: #FE0B19;
}
