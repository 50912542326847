/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.utils-drawer {
  position: fixed;
  right: 16px;
  bottom: 35px;
  display: flex;
  align-items: end;
  flex-direction: column;
  z-index: 300;
}
.utils-drawer .icon-box {
  width: 42px;
  height: 42px;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 21px;
  border: 1px solid #E8EAED;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.utils-drawer .icon-box .iconfont {
  font-size: 18px;
  color: #6A707C;
}
.utils-drawer .icon-box:hover .iconfont {
  color: #047FFE;
}
.utils-drawer .icon-box-back-last-pos {
  width: auto;
  padding: 16px;
  color: #047FFE;
}
.utils-drawer .icon-box-back-last-pos .dk-iconfont {
  margin-right: 4px;
  color: #047FFE !important;
}
.utils-drawer .icon-box-back-last-pos:hover {
  background-color: #E5F2FE;
}
