/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.footer {
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer.onlyOne {
  justify-content: flex-end;
}
.footer .memberBtn .btn {
  display: inline-block;
  border-radius: 4px;
  height: auto;
  font-size: 14px;
  line-height: 36px;
  border: none;
}
.footer .memberBtn .btn::after {
  display: none;
}
.footer .memberBtn .cancel {
  padding: 0 29px;
  margin-right: 12px;
  line-height: 36px;
  color: #444B4F;
  background: #F6F6F6;
}
.footer .memberBtn .confirm {
  padding: 0 24px;
  color: #ffffff;
  background: #047FFE;
}
.footer .memberBtn .confirm[disabled] {
  opacity: 0.5;
}
