/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
@media screen and (max-height: 800px) {
  .cooperFoldAuth-modal .ant-modal {
    top: 50px;
  }
}
.cooperFoldAuth-modal .ant-modal-header {
  border-bottom: none;
  border-radius: 8px;
  padding: 20px 24px 14px 24px;
}
.cooperFoldAuth-modal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  line-height: 28px;
}
.cooperFoldAuth-modal .ant-modal-content {
  padding: 0;
  border-radius: 8px;
}
.cooperFoldAuth-modal .ant-modal-body {
  padding: 0;
}
