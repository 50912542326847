/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.history-version {
  overflow-x: scroll;
  height: calc(100vh - 80px);
  width: calc(100vw - 80px);
}
.history-version .history-container {
  position: relative;
  height: calc(100% - 60px);
  padding-top: 0;
  margin-left: 296px;
}
.history-close {
  width: 20px;
  height: 20px;
}
.history-version-modal .close-icon {
  font-size: 24px;
}
.history-version-modal :global .ant-modal {
  padding-bottom: 0;
}
.history-version-modal :global .ant-modal-content {
  border-radius: 6px;
  overflow: hidden;
}
.history-version-modal :global .ant-modal-content .ant-modal-close-x {
  width: 44px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.history-version-modal :global .ant-modal-content .ant-modal-close {
  position: absolute;
  top: 0;
  right: 7px;
  background: #ffffff;
}
@media screen and (max-width: 996px) {
  .history-version-modal :global .ant-modal-content .ant-modal-close {
    right: 0;
  }
}
.history-version-modal :global .ant-modal-content .ant-modal-header {
  border-bottom: 1px solid #E8EAED;
  padding-left: 20px;
}
.history-version-modal :global .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 20px;
  font-weight: 500;
  color: #222A35;
  line-height: 28px;
}
.history-version-modal :global .ant-modal-content .ant-collapse-item .ant-collapse-header {
  margin: 0 8px;
}
.history-version-modal :global .ant-modal-content .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding-top: 4px;
}
.history-version-modal :global .ant-modal-content .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .menu {
  padding: 0 8px;
}
.history-version-modal :global .ant-modal-content .ant-modal-body {
  padding: 0;
}
