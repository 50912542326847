/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-subhead {
  padding-right: 14px;
  display: flex;
  align-items: center;
}
.cooper-subhead:nth-child(2) .cooper-subhead-title {
  padding: 8px 10px 8px;
}
.cooper-subhead .cooper-subhead-title {
  padding: 22px 0;
  color: #666;
  display: flex;
}
.cooper-subhead .cooper-subhead-title .lockTag {
  margin: 3px 0 0 3px;
  padding: 0 4px;
  height: 16px;
  line-height: 16px;
  background: #EFF0F2;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}
.cooper-subhead .cooper-subhead-title .lockTag .word {
  display: block;
  color: #666666;
  transform-origin: center center;
  transform: scale(0.83333333);
}
.cooper-subhead .cooper-subhead-title-text {
  float: left;
  margin-top: 6px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cooper-subhead .ant-breadcrumb {
  white-space: nowrap;
}
.cooper-subhead .ant-breadcrumb .bread-omit {
  border-radius: 4px;
}
.cooper-subhead .ant-breadcrumb .bread-omit:hover {
  background: #F7F7F7;
}
.cooper-subhead .ant-breadcrumb > ol > li {
  display: inline-block;
  cursor: pointer;
  height: 21px;
}
.cooper-subhead .ant-breadcrumb > ol > li:last-child .ant-breadcrumb-link {
  color: #2F343C;
  font-weight: 500;
}
.cooper-subhead .ant-breadcrumb > ol > li:first-child .ant-breadcrumb-link {
  padding-left: 0;
}
.cooper-subhead .ant-breadcrumb .ant-breadcrumb-separator {
  float: left;
  position: relative;
  margin: 0;
  top: -1px;
}
.cooper-subhead .ant-breadcrumb .ant-breadcrumb-link,
.cooper-subhead .ant-breadcrumb .ant-breadcrumb-link a,
.cooper-subhead .ant-breadcrumb .ant-breadcrumb-link span {
  color: #666666;
}
.cooper-subhead .ant-breadcrumb .ant-breadcrumb-link:hover,
.cooper-subhead .ant-breadcrumb .ant-breadcrumb-link:hover a {
  color: #047FFE;
}
.cooper-subhead .ant-breadcrumb .ant-breadcrumb-link {
  overflow: hidden;
  float: left;
  max-width: 155px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  color: #2F343C;
  line-height: 21px;
  padding: 0 5px;
  font-size: 16px;
}
.cooper-subhead .icon-separator {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-image: url(./icon/icon_mianbaoxie.svg);
  background-size: 8px 8px;
}
.is-dc-subhead .ant-breadcrumb > span:nth-child(1) .ant-breadcrumb-separator {
  margin-left: 2px;
}
.is-dc-subhead .ant-breadcrumb > span:nth-child(2) .ant-breadcrumb-separator {
  margin-left: 2px;
}
.is-dc-subhead .ant-breadcrumb-link {
  max-width: 84px;
}
.is-dc-subhead .ant-breadcrumb-separator {
  margin: 0 2px 0 -4px;
}
.bread-crumb-container .ant-popover-arrow {
  display: none;
}
.pop-ellipsis {
  width: 400px;
  max-height: 452px;
  background: #FFFFFF;
  border-radius: 6px;
  overflow-y: scroll;
}
.pop-ellipsis::-webkit-scrollbar {
  display: none !important;
}
.pop-ellipsis .folder-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}
.pop-ellipsis .folder-item:hover {
  background: #F7F7F7;
}
.pop-ellipsis .folder-item .folder-item-link {
  width: 100%;
  display: flex;
  align-items: center;
}
.pop-ellipsis .folder-item .folder-item-link:hover {
  cursor: pointer;
}
.pop-ellipsis .folder-item .folder-item-link:hover .folder-name {
  color: #0066FF;
}
.pop-ellipsis .folder-item .folder-item-link .folder-icon {
  width: 28px;
  height: 28px;
  object-fit: cover;
  vertical-align: middle;
  margin-right: 8px;
}
.pop-ellipsis .folder-item .folder-item-link .folder-name {
  color: #2F343C;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cooper-subhead-teamManage-tooltip {
  z-index: 900;
}
.cooper-subhead-teamManage-tooltip .teamManage-tip {
  color: #3D8CDF;
  cursor: pointer;
  font-weight: 700;
}
.cooper-subhead-teamManage-tooltip .ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.8);
}
.sub-header-share .cooper-subhead-title {
  padding: 0 !important;
}
.sub-header-share .ant-breadcrumb .ant-breadcrumb-link {
  font-size: 12px !important;
  line-height: 20px !important;
  color: rgba(34, 42, 53, 0.4);
}
.sub-header-share.ant-breadcrumb > ol > li:last-child .ant-breadcrumb-link {
  color: rgba(34, 42, 53, 0.7);
}
