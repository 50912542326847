/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.batch-operate {
  position: absolute;
  left: 24px;
  bottom: 0px;
  width: auto;
  height: 45px;
  overflow: hidden;
  background-color: white;
  display: flex;
  align-items: center;
}
.batch-operate-main {
  flex: 0 0 auto;
}
.batch-operate-cancel {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
