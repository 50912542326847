/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.resizer {
  font-style: normal;
  font-size: 10px;
  color: #999;
  cursor: ew-resize;
  width: 20px;
  text-align: center;
  user-select: none;
}
