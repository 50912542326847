/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.meta-info {
  pointer-events: none;
  cursor: auto;
  padding-top: 12px;
  border-top: 1px solid #EBF0F4;
}
.meta-info .file-info {
  margin: 4px 0 10px;
}
.meta-info .file-info .file-type {
  margin-right: 8px;
  font-size: 24px;
  color: #505050;
  line-height: 29px;
}
.meta-info .file-info .file-size {
  font-size: 12px;
  color: #888888;
}
.meta-info .info {
  height: 18px;
  font-size: 12px;
  margin-top: 4px;
}
.meta-info .info .username {
  color: #505050;
  margin-right: 6px;
}
.meta-info .info .time {
  color: #888888;
}
