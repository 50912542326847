/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.member-list-of-resource {
  padding: 0px 24px;
}
.member-list-of-resource .list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.member-list-of-resource .list-header .list-header-title {
  font-size: 16px;
  font-weight: 600;
  color: #222A35;
  line-height: 22px;
  word-break: break-all;
}
.member-list-of-resource .list-header .title-action {
  display: flex;
  align-items: center;
  justify-content: center;
}
.member-list-of-resource .list-header .title-action .split-line {
  height: 12px;
  width: 1px;
  background-color: #E8E9EA;
  margin: 0 8px;
}
.member-list-of-resource .list-body-area {
  padding-top: 16px;
  padding-bottom: 20px;
}
.member-list-of-resource .list-footer {
  margin: 0 -24px;
  background: #F5F6F8;
  padding: 8px 24px 8px 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.member-list-of-resource .list-footer .user-perm-show {
  color: #656A72;
  font-size: 12px;
  display: flex;
  align-items: center;
}
