/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.preview-wrap {
  height: 100%;
}
.preview-wrap .header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E3E5EA;
}
.preview-wrap .header .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
  margin-left: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #222A35;
  line-height: 50px;
}
.preview-wrap .preview {
  padding-right: 25px;
  width: 100%;
  height: 100%;
  border: none;
  background: #F2F4F7;
}
.preview-wrap .preview.hasHeader {
  height: calc(100% - 50px);
}
.preview-wrap .preview-btn {
  border: none;
  color: #444B4F;
  font-size: 14px;
}
.preview-wrap .preview-btn::after {
  display: none;
}
.preview-wrap .cannot-preview-btn {
  margin: 16px auto 0;
  padding: 0 29px;
  background: #047FFE;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 44px;
  height: 44px;
}
.preview-wrap .cannot-preview-btn[disabled] {
  background: rgba(4, 127, 254, 0.5);
}
.preview-wrap .cannot-preview-btn:hover {
  color: #FFFFFF;
  background: #047FFE;
}
.preview-wrap .cannot-preview-btn:active {
  color: #FFFFFF;
  background: #047FFE;
}
.preview-wrap .cannot-preview-btn:focus {
  color: #FFFFFF;
  background: #047FFE;
}
.preview-wrap .can-preview-btn {
  margin-right: 24px;
  padding: 7px 10px 7px 9px;
  height: 34px;
  line-height: 20px;
  border-radius: 4px;
}
.preview-wrap .can-preview-btn:hover {
  color: #444B4F;
  background: #F6F6F6;
}
.preview-wrap .can-preview-btn:active {
  color: #444B4F;
}
.preview-wrap .can-preview-btn:focus {
  color: #444B4F;
}
.preview-wrap .can-preview-btn[disabled] {
  color: #aaaaaa;
}
.preview-wrap .can-preview-btn[disabled] .icon {
  color: #aaaaaa;
}
.preview-wrap .can-preview-btn .icon {
  position: relative;
  top: -1px;
  vertical-align: middle;
  font-size: 18px;
  color: #666666;
  margin-right: 6px;
}
.preview-wrap .error-desc {
  margin-top: 6px;
  font-size: 16px;
  line-height: 24px;
}
