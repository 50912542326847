/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.permission-tip {
  text-align: center;
  color: rgba(34, 42, 53, 0.5);
  font-size: 12px !important;
  display: inline-block;
}
.team-set {
  margin-left: 4px;
}
.permissions-tips-content {
  display: inline-block;
  height: 23px;
  line-height: 23px;
  padding: 0 4px;
  margin-left: 4px;
  font-size: 12px;
  color: rgba(34, 42, 53, 0.5);
  align-items: center;
  justify-content: center;
}
.hasHover {
  cursor: pointer;
}
.hasHover:hover {
  color: #222A35;
  background-color: #E8E9EA;
  border-radius: 4px;
}
.hasHover:hover .permission-tip {
  color: #222A35;
}
.pop {
  padding: 12px;
  font-family: PingFang SC;
}
.pop .pop-desc-tip {
  font-size: 12px;
  color: #909499;
  margin-bottom: 10px;
}
.pop .pop-title {
  font-size: 16px;
  color: #222A35;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 6px;
}
.pop .pop-content {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(34, 42, 53, 0.1);
}
.pop .pop-content .pop-content-item-con {
  padding: 0 12px;
  height: 37px;
  line-height: 37px;
  color: rgba(34, 42, 53, 0.9);
}
.pop .pop-content .pop-content-item-title {
  background-color: #F7F9FA;
  color: #222A35;
  font-weight: 500;
  font-size: 14px;
}
.pop .pop-content .pop-content-item-value {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  text-align: left;
  font-size: 14px;
}
.pop .pop-desc {
  font-size: 12px;
  color: rgba(34, 42, 53, 0.5);
  height: 18px;
  line-height: 18px;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
